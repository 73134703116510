import { AbstractControl, ValidatorFn } from '@angular/forms';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

export function ValidateMobile(cc = null): ValidatorFn {
	const countryCode = cc || 'IN';
	return (control: AbstractControl) => {
		const ctrlValue = control.value;

		if (!ctrlValue) {
			return null;
		}

		const evalPhone = parsePhoneNumberFromString(ctrlValue, countryCode);
		if (evalPhone && evalPhone.isValid()) {
			return null;
		}

		return {
			isValid: false,
			field: 'mobile',
			message: 'Not a valid mobile!'
		};
	};
}
