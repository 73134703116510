import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';

@Injectable()
export class TargetService {
	public targetUrl = `${environment.api_url}/target`;
	constructor(
        private apiService: ApiService
	) { }

	addTarget(data) {
		const route = `${this.targetUrl}/addTarget`;
		return this.apiService.post(route, data);
	}

	updateTarget(data) {
		const route = `${this.targetUrl}/updateTarget`;
		return this.apiService.put(route, data);
	}

	deleteTarget(id, batchId) {
		const route = `${this.targetUrl}/deleteTarget/${id}/${batchId}`;
		return this.apiService.delete(route);
	}
}
