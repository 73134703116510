export const message = {
	'signUpForm': {
		'tooltip': {
			'username': 'Must contain 6 to 15 characters. Only letters, numbers, hyphen and underscore are allowed'
		},
		'user': {
			'exists': 'Username already taken',
			'pattern': 'Special characters other than _ and - are not allowed',
			'maxLen': 'Maximum 15 characters allowed',
			'minLen': 'Minimum 6 characters are needed',
			'email_mobile_exists': 'Phone/Email already exists'

		},
		'notValid': 'Enter valid details',
		'required': 'Required',
		'mobileOrEmail': {
			'notValid': 'Enter a valid Email/Mobile Number',
			'mobileExists': 'Mobile already taken',
			'emailExists': 'Email already taken'
		},
		'password': {
			'weak': 'Weak',
			'average': 'Average',
			'strong': 'Strong',
			'maxLen': 'Maximum 15 characters allowed',
			'minLen': 'Minimum 6 characters are needed',
			'notMatching': 'Passwords not matching'

		},
		'failure': 'Sign-Up unsuccessful, please provide valid details.'
	},
	'login': {
		'fail': 'Login unsuccessful. Please enter valid details.',
		'forgotPassword': 'Forgot Username/Password?'
	},
	'logout': {
		'success': 'Logout successful.'
	},
	'otp': {
		'otpSent': 'An OTP has been sent to you.Please verify your details',
		'passwordSentToMobile': 'Generated password has been sent to registered Mobile number',
		'passwordSentToEmail': 'Generated password has been sent to registered Email',
		'invalid': 'OTP should contain 6 digits',
		'sent': 'OTP sent.',
		'verified': 'OTP Verification succesful.',
		'max_generate_attempts': 'Only 3 OTP generate attempts allowed in 30 mins.',
		'max_verify_attempts': 'Reached maximum number of attempts.',
		'expired': 'OTP Expired!',
		'regexFail': 'Should contain 6 digits',
		'tryAgain': 'Please try again after sometime'
	},
	'profile': {
		'phoneExists': 'Entered phone number already taken',
		'emailExists': 'Entered email number already taken',
		'phoneInvalid': 'Please enter a valid phone number',
		'emailInvalid': 'Please enter a valid email',
		'urlInvalid': 'Please enter a valid URL',
		'githubUsernameInvalid': 'Please enter a valid Github username'
	}
};
