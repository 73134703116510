export const usernameregex: any = /^[A-Za-z0-9-_]+$/;
export const genericNames: any = /^[A-Za-z\s0-9-.(),'&/]*$/;
export const emailregex: any = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
export const possiblemobileregex: any = /^(\+)?[0-9]*$/;
export const linkedInRegex: any = /^https?:\/\/(www\.)?(linkedin\.com\/).+$/;
export const facebookRegex: any = /^https?:\/\/((www|m)\.)?((fb\.com)|(facebook\.com)\/).+$/;
export const twitterRegex: any = /^https?:\/\/(www\.)?(twitter\.com\/).+$/;
export const quoraRegex: any = /^https?:\/\/(www\.)?(quora\.com\/profile\/).+$/;
export const assignmentRegex: any = /^https?:\/\/(www\.)?(classroom\.github\.com\/).+$/;
export const personalWebsiteRegex: any = /^(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)$/;
export const githubUsernameRegex: any = /^[A-Za-z\d](?:[A-Za-z\d]|-(?=[A-Za-z\d])){0,39}$/;
