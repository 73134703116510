<h1 mat-dialog-title class="dialog-title">{{data.heading}}</h1>
<div mat-dialog-content>
	<table>
		<tr *ngIf="!hidePlatforms.hideHackerRank">
			<td class="title first">
				<a href="https://www.hackerrank.com/domains/algorithms" target="_blank">
					HackerRank
				</a>
			</td>
			<td class="value first">{{ data.body.overall.hr }}</td>
		</tr>
		<tr>
			<td class="title">
				<a href="https://www.hackerrank.com/contests/smart-interviews-basic" target="_blank">
					Smart Interviews Basic
				</a>
			</td>
			<td class="value">{{ data.body.overall.siBasic }}</td>
		</tr>
		<tr *ngIf="data.isRegistered && !data.phaseOne">
			<td class="title">
				<a href="https://www.hackerrank.com/contests/smart-interviews" target="_blank">
					Smart Interviews Primary
				</a>
			</td>
			<td class="value">{{ data.body.overall.si }}</td>
		</tr>
		<tr *ngIf="!hidePlatforms.hideLeetcode">
			<td class="title">
				<a href="https://www.leetcode.com/problemset/all/" target="_blank">
					LeetCode
				</a>
			</td>
			<td class="value">{{ data.body.overall.lc }}</td>
		</tr>
		<tr *ngIf="(data.isRegistered && !data.phaseOne && !hidePlatforms.hideInterviewBit)">
			<td class="title">
				<a href="https://interviewbit.com/courses/programming/" target="_blank">
					InterviewBit
				</a>
			</td>
			<td class="value">{{ data.body.overall.ib }}</td>
		</tr>
		<tr *ngIf="(!hidePlatforms.hideCodeChef)">
			<td class="title">
				<a href="https://www.codechef.com/problems/school/" target="_blank">
					CodeChef
				</a>
			</td>
			<td class="value">{{ data.body.overall.cc }}</td>
		</tr>
		<tr *ngIf="!hidePlatforms.hideCodeforces">
			<td class="title">
				<a href="https://codeforces.com/problemset?order=BY_SOLVED_DESC" target="_blank">
					Codeforces
				</a>
			</td>
			<td class="value">{{ data.body.overall.cf }}</td>
		</tr>
		<tr *ngIf="!hidePlatforms.hideSpoj">
			<td class="title">
				<a href="https://www.spoj.com/problems/classical/sort=6" target="_blank">
					Spoj
				</a>
			</td>
			<td class="value">{{ data.body.overall.spoj }}</td>
		</tr>
		<tr class="last-row">
			<td class="title last">Overall Score</td>
			<td class="value">{{ data.body.overall.overall }}</td>
		</tr>
		<tr *ngIf="!data.isRegistered">
			<td class="title">Eligiblity [Overall Score >= {{ data.minThreshold }}]</td>
			<ng-container *ngIf="data.body.overall.overall >= data.minThreshold">
				<td class="value" style="color:green">Yes</td>
			</ng-container>
			<ng-container *ngIf="data.body.overall.overall < data.minThreshold">
				<td class="value" style="color:red">No</td>
			</ng-container>
		</tr>
		<ng-container
			*ngIf="(!data.isRegistered && data.body.overall.overall >= data.minThreshold ) && !invokedAtPayment">
			<tr>
				<td colspan="2" style="font-weight: bold;font-size: 1.1em;">
					You can now go ahead and
					<a href="/smart-coder">enroll</a>.
				</td>
			</tr>
		</ng-container>

	</table>
</div>
<div mat-dialog-actions>
	<button mat-raised-button color="primary" (click)="onNoClick()">Ok</button>
</div>