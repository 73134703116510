<div>
	<p>Please fill these details and we will notify you when registrations start.</p>
	<br />
	<div mat-dialog-content [formGroup]="notifyMeForm">
		<div fxLayout="column">

			<!-- Full Name -->
			<mat-form-field fxFlex appearance="outline">
				<mat-label>Full Name</mat-label>
				<input required matInput formControlName="fullname" minlength="3" maxlength="100"
					placeholder="Enter your Full Name" />
				<mat-error>{{getErrorMessage('fullname', notifyMeForm)}}</mat-error>
			</mat-form-field>

			<mat-form-field fxFlex appearance="outline">
				<mat-label>Batch name</mat-label>
				<input required matInput formControlName="batchName" placeholder="Enter batchName" />
				<mat-error>{{getErrorMessage('batchName', notifyMeForm)}}</mat-error>
			</mat-form-field>

			<div fxFlex="100%" fxLayout="row" fxLayoutGap="5px">
				<mat-form-field appearance="outline" fxFlex="0 0 100px">
					<mat-select #cc_mobile formControlName="cc"
						(selectionChange)="setCCValidator(notifyMeForm.controls['mobile'], cc_mobile)">
						<mat-option *ngFor="let cc of countriesList" [value]="cc">
							{{cc}} (+{{getCountryCallingCode(cc)}})
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" fxFlex>
					<mat-label>Mobile</mat-label>
					<input #mobile matInput maxlength="15" placeholder="Phone" formControlName="mobile"
						(keyup)="asYouType(mobile, cc_mobile, notifyMeForm.controls['mobile'])"
						(keydown)="helpers.onlyNumbers($event);">
					<mat-error *ngIf="!notifyMeForm.controls['mobile'].errors?.isValid">
						{{notifyMeForm.controls['mobile'].errors?.message}}
					</mat-error>
					<mat-error *ngIf="notifyMeForm.controls['mobile'].errors?.required">
						This field is <strong>required</strong>
					</mat-error>
				</mat-form-field>
			</div>

			<!-- Email Id -->
			<mat-form-field fxFlex appearance="outline">
				<mat-label>Email Id</mat-label>
				<input required matInput formControlName="email" type="email" />
				<mat-error>{{getErrorMessage('email', notifyMeForm)}}</mat-error>
			</mat-form-field>

		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-button (click)="onDismiss()">Cancel</button>
		<button [disabled]="!notifyMeForm.valid" mat-raised-button color="primary" (click)="sendMessage()"
			mat-button>Submit</button>
	</div>
</div>