import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {
	getCountries,
	getCountryCallingCode as getCountryCallingCodeImported,
	parsePhoneNumberFromString
}
	from 'libphonenumber-js/max';
import { UserService, OtpService, HelpersService, JwtService } from '../shared';
import { message } from '../shared/messages/messages';
import { ToastrService } from 'ngx-toastr';
import * as regex from '../registration/variables';
import { finalize, take } from 'rxjs/operators';
import { ValidateMobile } from '../shared/validators/mobile-validation';

@Component({
	selector: 'app-otp',
	templateUrl: './otp.component.html',
	styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private otpService: OtpService,
		private userService: UserService,
		private jwtService: JwtService,
		private fb: FormBuilder,
		private toastr: ToastrService,
		private helpers: HelpersService
	) {
		this.otpForm = this.fb.group({});
	}
	title: string;
	otpForm: FormGroup;
	otpType = '';
	messageOtp = message;

	otpSubmitError = '';

	emailOrMobile = 'Mobile';
	countriesList: any;
	getCountryCallingCode = getCountryCallingCodeImported;

	emailOrMobileSelection(element) {
		this.emailOrMobile = element.value;
		if (this.emailOrMobile === 'Mobile') {
			this.otpForm.addControl('cc', new FormControl('IN', [Validators.required]));
			this.otpForm.addControl('mobile', new FormControl('', [Validators.required, ValidateMobile()]));
			this.otpForm.removeControl('email');
		} else {
			this.otpForm.removeControl('cc');
			this.otpForm.removeControl('mobile');
			this.otpForm.addControl('email', new FormControl('', [Validators.required, Validators.email]));
		}
	}

	getEmailErrorMessage() {
		return this.otpForm.controls['email'].errors === null ? '' :
			this.otpForm.controls['email'].errors.required ? 'Required' :
				this.otpForm.controls['email'].errors ? 'Invalid email' :
					'';
	}

	getMobileErrorMessage() {
		return this.otpForm.controls['mobile'].errors === null ? '' :
			this.otpForm.controls['mobile'].errors.required ? 'Required' :
				this.otpForm.controls['mobile'].errors ? this.otpForm.controls['mobile'].errors.message :
					'';
	}

	resendOtp() {
		if (this.helpers.isLoading) {
			return;
		}
		this.helpers.showLoader();
		this.otpService.resendOtp('/resendOtp')
			.pipe(take(1), finalize(() => this.helpers.hideLoader()))
			.subscribe((data) => {
				if (data.status) {
					this.toastr.success('OTP resent!');
				} else {
					this.toastr.error(data.message);
				}
			}, (err) => {
				this.toastr.error(err.message);
			});
	}

	/**
	 * TODO: Refactor this. Move to shared/ and reuse in other components
	 * Overwrites the input with the formatted number
	 * @param {HTMLInputElement} field
	 * @param {MatSelect} cc country code
	 */
	public asYouType(field, cc, formControl): void {
		const parsedNumber = parsePhoneNumberFromString(field.value, cc.selected.value);
		if (parsedNumber) {
			this.setCCValidator(formControl, cc);
			field.value = parsePhoneNumberFromString(field.value, cc.selected.value).nationalNumber;
			formControl.setValue(field.value);
		}
	}

	/** TODO: Refactor this. Move to shared/ and reuse in other components */
	public setCCValidator(formControl: FormControl, cc): void {
		formControl.clearValidators();
		formControl.setValidators([Validators.required, ValidateMobile(cc.selected.value)]);
		formControl.markAsTouched({ onlySelf: true });
		formControl.markAsDirty({ onlySelf: true });
		formControl.updateValueAndValidity();
	}

	/**
	 * @param event submit button
	 * @description Validates submitted data and sends the user to relevant
	 * route on success.
	 */
	submitForm(event) {
		/**
		 * This condition will be true when user selects forgot password
		 */
		if (this.otpType === 'forgot-password') {
			if (!this.otpForm.valid) {
				this.helpers.validateAllFields(this.otpForm);
				return;
			}


			if (this.helpers.isLoading) {
				return;
			}
			this.helpers.showLoader();

			const type = this.emailOrMobile;
			const data = {
				type: type.toLowerCase(),
				value: this.otpForm.value.email || this.otpForm.value.mobile,
				cc: this.otpForm.value.cc
			};

			if (this.otpForm.value.mobile) {
				const parsedNumber = parsePhoneNumberFromString(this.otpForm.value.mobile, this.otpForm.value.cc);
				data.value = parsedNumber ? parsedNumber.number : null;
			}

			this.otpService.sendOtpTo(data)
				.pipe(take(1))
				.subscribe((res) => {
					if (res.status) {
						this.toastr.success(res.message);
						this.jwtService.saveUsername(res.data.username.toLowerCase(),
							res.data.roleLevel, res.data.role);
						this.jwtService.userTemp = data.value;
						this.jwtService.type = type;
						this.router.navigate(['/verifyotp']);
					} else {
						this.toastr.error(res.message);
						this.helpers.hideLoader();
					}
				},
					(err) => {
						this.toastr.error(err.message);
						this.helpers.hideLoader();
					});
		} else {
			/**
			 * This is the /verifyotp flow
			 */
			if (this.otpForm.controls['otpValue'].errors != null || this.otpForm.controls['otpValue'].pristine) {
				this.otpSubmitError = (this.messageOtp.otp.invalid);
				return;
			}


			if (this.helpers.isLoading) {
				return;
			}
			this.helpers.showLoader();

			this.otpService.verifyOtp(this.otpType, this.otpForm.value)
				.pipe(take(1))
				.subscribe((data) => {
					if (data.status) {
						if (this.jwtService.getHash()) {
							this.userService.storeVerifiedField().pipe(take(1)).subscribe((res) => {
								this.jwtService.removeHash();
								this.toastr.success(res.message);

								// Updating local user object with the new data
								this.userService.currentUser
									.pipe(take(1))
									.subscribe((user) => {
										const val = this.jwtService.userTemp;
										const type = regex.emailregex.test(val) ? 'email' : 'mobile';
										user[type] = val;
										this.userService.updateCurrentUser(user);
									});
								this.userService.updateVerified();
								this.route.queryParams.pipe(take(1)).subscribe((param) => {
									if (param.returnUrl) {
										this.router.navigate([param.returnUrl]);
									} else if (param.batch) {
										this.userService.registerWithBatch(param.batch)
											.pipe(take(1))
											.subscribe((response) => {
												this.toastr.info(response.message);
											}, (err) => {
												this.toastr.error(err.message);
											});
										window.open('/smart-coder', '_self');
									} else {
										window.open('/smart-coder', '_self');
									}
								});
							}, (err) => {
								this.toastr.error(err.message);
								this.helpers.hideLoader();
							});
						} else {
							this.otpService.sendPassword(this.otpType, this.otpForm.value).subscribe(
								(res) => {
									this.toastr.success(res.message);

									this.router.navigate(['/login']);
								},
								(err) => {
									this.toastr.error(err.message);
									this.helpers.hideLoader();
								}
							);
						}
					} else {
						this.toastr.error(data.message);
						this.helpers.hideLoader();
					}
				},
					(err) => {
						this.toastr.error(err.message);
						this.helpers.hideLoader();
					}
				);
		}
	}

	ngOnInit() {
		this.emailOrMobile = 'Mobile';
		this.countriesList = getCountries();

		this.otpForm.addControl('cc', new FormControl('IN', [Validators.required]));
		this.otpForm.addControl('mobile', new FormControl('', [Validators.required, ValidateMobile()]));

		this.helpers.hideLoader();
		this.route.url.subscribe((data) => {
			this.otpType = data[data.length - 1].path;
			this.title = this.otpType === 'forgot-password' ? 'Send Password' : 'Verify OTP';
		});
		this.setOtp(this.otpType);
	}

	setOtp(type: String) {
		if (type === 'verifyotp') {
			this.otpForm.addControl('otpValue',
				new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{6}$/)]));
		}
	}
}
