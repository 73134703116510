import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserService } from '../../shared/services';

type Reason = {
    _id: string;
    reason: string;
    checked: boolean;
}

@Component({
	selector: 'app-unsubscribe',
	templateUrl: './unsubscribe.component.html',
	styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit, OnDestroy {
	public unsubscriptionForm: FormGroup;
	public reasons: Reason[] = [];
	public unsubscribeReasonsIds = [];
	private subscription = new Subscription();

	constructor(private toastr: ToastrService,
        private userService: UserService,
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute) {
		this.unsubscriptionForm = this.formBuilder.group({
			checkboxes: new FormGroup({})
		});
	}

	ngOnInit(): void {
		this.populateUnsubscriptionReasons();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
	public async populateUnsubscriptionReasons() {
		try {
			this.subscription.add(this.userService.getUnsubscriptionReasons().subscribe((results) => {
				if (results.status) {
					this.reasons = results.data;
					this.reasons.forEach((item) => {
						item.checked = false;
						const checkboxControl = this.unsubscriptionForm.get('checkboxes');
						if (checkboxControl instanceof FormGroup) {
							(<FormGroup>checkboxControl).addControl(item.reason, new FormControl(true));
						}
					});
				} else {
					throw new Error(results.message);
				}
			}));
		} catch (error) {
			this.toastr.error('Something went wrong, please try again later', 'Error');
		}
	}

	public onCheckReason(event, item) {
		if (event.checked) {
			/** If checked, add this item's _id to array unsubscribeReasonsIds */
			if (this.unsubscribeReasonsIds.indexOf(item._id) === -1) {
				this.unsubscribeReasonsIds.push(item._id);
			}
		} else {
			/** If unchecked, remove this item's _id from array unsubscribeReasonsIds */
			const index = this.unsubscribeReasonsIds.indexOf(item._id);
			if (index !== -1) {
				this.unsubscribeReasonsIds.splice(index, 1);
			}
		}
	}

	public onDismiss() {
		this.router.navigate(['/']);
	}

	/** On form submission, check the numebr of reasons and unsubscribe if there is at least one reason selected */
	public submitUnsubscriptionForm() {
		if (!this.unsubscriptionForm.valid || this.unsubscribeReasonsIds.length === 0) {
			this.toastr.error('Select at least one reason to unsubscribe.');
			return;
		}
		this.unsubscribeLead();
	}

	private unsubscribeLead() {
		const data = {
			referrerId: this.route.snapshot.params['referrerId'],
			unsubscribeReasons: this.unsubscribeReasonsIds
		};
		this.subscription.add(this.userService.unsubscribeFinally(data).subscribe((results) => {
			if (results.status) {
				this.toastr.success('Unsubscribed successfully!', 'Success');
				setTimeout(() => {
					document.location.href = 'https://smartinterviews.in/';
				}, 3000);
			} else {
				this.toastr.error('Error in unsubscription, please try again later!', 'Error');
			}
		}));
	}
}
