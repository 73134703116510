import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
	constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data:
			{ title: string, message: string, btnOkText?: string, btnCancelText?: string }
	) { }


	onDismiss(): void {
		this.dialogRef.close(false);
	}

	onAccept(): void {
		this.dialogRef.close(true);
	}

	ngOnInit() {
	}
}
