import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContactUsComponent } from './shared/contact-us/contact-us.component';

const routes: Routes = [
	{
		path: 'editprofile', loadChildren: () =>
			import('./editprofile/editprofile.module').then((m) => m.EditprofileModule)
	},

	{ path: 'report', loadChildren: () => import('./report/report.module').then((m) => m.ReportModule) },

	{ path: 'admin', loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule) },

	{
		path: 'samplecodes', loadChildren: () =>
			import('./samplecodes/samplecodes.module').then((m) => m.SamplecodesModule)
	},

	{ path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule) },

	// { path: 'contact-us', component: ContactUsComponent },
	{ path: 'cheatcodes', redirectTo: '' },
	// {
	// 	path: 'privacy-policy', loadChildren: () =>
	// 		import('./privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule)
	// },
	// {
	// 	path: 'terms-and-conditions', loadChildren: () =>
	// 		import('./terms-and-conditions/terms-and-conditions.module').then((m) => m.TermsAndConditionsModule)
	// },
	{ path: 'register', loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentModule) },
	{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
	],
	exports: [RouterModule]
})

export class AppRoutingModule { }
