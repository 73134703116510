import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

import { environment } from '../../environments/environment';
import { NoAuthGuard } from './no-auth-guard.service';
import { AuthComponent } from './auth.component';
import { SharedModule } from '../shared';
import { OtpModule } from '../otp/otp.module';
import { NgMaterialModule } from '../shared/ng-material/ng-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

const authRouting = RouterModule.forChild([
	{
		path: 'login',
		canActivate: [NoAuthGuard],
		component: AuthComponent
	},
	{
		path: 'register',
		// canActivate : [AuthGuard],
		component: AuthComponent
	}
]);

@NgModule({
	imports: [
		authRouting,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgMaterialModule,
		FlexLayoutModule,
		SharedModule,
		OtpModule,
		RecaptchaModule,
		RecaptchaFormsModule,
	],
	declarations: [
		AuthComponent
		// PasswordValidation
	],
	providers: [
		NoAuthGuard
	]
})
export class AuthModule { }
