<div class="body-content">
	<div class="mat-elevation-z1">
		<mat-progress-bar color="accent" *ngIf="dataSource.loading$ | async" mode="indeterminate"></mat-progress-bar>
		<div class="filters">
			<mat-chip-list selectable>
				<mat-chip *ngFor="let tag of activeBatches; let i=index"
					(click)="tag.batchName !== batchName && filterData(tag.batchName)"
					[selected]="tag.batchName === batchName" removable (removed)="resetData()" [ngStyle]="{'border-width': '2px', 'border-color':getBackgroundColor(tag.role),
                     'border-style': 'solid'}">
					{{tag.batchName}}
					<mat-icon matChipRemove *ngIf="tag.batchName === batchName">cancel</mat-icon>
				</mat-chip>
			</mat-chip-list>
		</div>
		<div class="filters">
			<mat-chip-list selectable>
				<mat-chip *ngFor="let tag of nonActiveBatches; let i=index"
					(click)="tag.batchName !== batchName && filterData(tag.batchName)"
					[selected]="tag.batchName === batchName" removable (removed)="resetData()" [ngStyle]="{'border-width': '2px', 'border-color':getBackgroundColor(tag.role),
                     'border-style': 'solid'}">
					{{tag.batchName}}
					<mat-icon matChipRemove *ngIf="tag.batchName === batchName">cancel</mat-icon>
				</mat-chip>
			</mat-chip-list>
		</div>
		<div class="report-icon" *ngIf="batchName !== ''">
			<a target="_blank" [matTooltipShowDelay]="500" [routerLink]="'/report/'+batchName"
				matTooltip="Batch report">
				<mat-icon>launch</mat-icon>
			</a>
		</div>
		<div class="scrollable leaderboard-container">
			<table mat-table [dataSource]="dataSource">

				<ng-container matColumnDef="rank">
					<th mat-header-cell *matHeaderCellDef> Rank </th>
					<td mat-cell *matCellDef="let row; let index = index">
						<div>
							{{row.currentRank}}
							<span #tip="matTooltip" (touchstart)="tip.show()" (touchend)="tip.hide()"
								matTooltip="Rank: {{row.weekOldRank?.rank}} | Date: {{row.weekOldRank?.date | date}}"
								matTooltipShowDelay="500" matTooltipPosition="right"
								*ngIf="batchName == '' && row.weekOldRank && row.weekOldRank?.rank - row.currentRank != 0">
								<span *ngIf="batchName == '' && row.weekOldRank?.rank - row.currentRank > 0">
									<mat-icon [ngStyle]="{'color': 'green'}">
										arrow_drop_up</mat-icon>
								</span>
								<span *ngIf="batchName == '' && row.weekOldRank?.rank - row.currentRank < 0">
									<mat-icon [ngStyle]="{'color': 'red'}">
										arrow_drop_down</mat-icon>
								</span>
							</span>
						</div>
					</td>
				</ng-container>

				<ng-container matColumnDef="rollNumber">
					<th mat-header-cell *matHeaderCellDef> Roll No.</th>
					<td mat-cell *matCellDef="let row">
						<span *ngIf="row?.rollNumber">
							{{row?.rollNumber}}
						</span>
					</td>
				</ng-container>

				<ng-container matColumnDef="username">
					<th mat-header-cell *matHeaderCellDef> Username </th>
					<td mat-cell *matCellDef="let row">
						<app-username [username]="row.username" [dpImageFile]="row.dpImageFile"
							[publicView]="row.publicView" [link]="true">
						</app-username>
					</td>
				</ng-container>

				<ng-container matColumnDef="overall">
					<th mat-header-cell *matHeaderCellDef> Overall Score </th>
					<td mat-cell *matCellDef="let row"> {{row.overall.latestScore.score}} </td>
				</ng-container>

				<ng-container matColumnDef="disclaimer">
					<td mat-footer-cell *matFooterCellDef colspan="3" class="disclaimer-message">
						Since you are not registered to any Smart Coder batch, your name won’t be displayed in the
						leaderboard. <br /> Only registered users can access the complete leaderboard.
					</td>
				</ng-container>

				<ng-container matColumnDef="notification">
					<td mat-footer-cell *matFooterCellDef colspan="3" class="disclaimer-message">
						Scores will automatically be updated for active users, once every 24 hours.
					</td>
				</ng-container>

				<ng-container matColumnDef="registerMessage">
					<td mat-footer-cell *matFooterCellDef colspan="3" class="disclaimer-message">
						Only registered users can access the complete leaderboard.
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns" class="main-header"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"
					[ngClass]="{'currentUser': row.currentUser}"></tr>
				<tr mat-footer-row *matFooterRowDef="['disclaimer']"
					[hidden]="(dataSource.loading$ | async) || !(isLoggedIn && !registeredUser)">
				</tr>
				<tr mat-footer-row *matFooterRowDef="['notification']"
					[hidden]="(dataSource.loading$ | async) || !(registeredUser)"></tr>
				<tr mat-footer-row *matFooterRowDef="['registerMessage']"
					[hidden]="(dataSource.loading$ | async) || isLoggedIn"></tr>
			</table>
			<mat-paginator #paginator [length]=" dataSource.totalCount$ | async" [pageSize]="pageSize"
				[pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
			</mat-paginator>
		</div>
	</div>
</div>
<layout-footer></layout-footer>