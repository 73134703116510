import {
	Directive,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';

import { UserService } from './services/user.service';

@Directive({ selector: '[showAuthed]' })
export class ShowAuthedDirective implements OnInit, OnDestroy {
	constructor(
    private templateRef: TemplateRef<any>,
    private userService: UserService,
    private viewContainer: ViewContainerRef
	) { }

	condition: boolean;
	subscription = new Subscription();

	ngOnInit() {
		this.subscription = this.userService.isAuthenticated.subscribe(
			(isAuthenticated) => {
				if (isAuthenticated && this.condition || !isAuthenticated && !this.condition) {
					if (this.viewContainer.length === 0) {
						this.viewContainer.createEmbeddedView(this.templateRef);
					}
				} else {
					this.viewContainer.clear();
				}
			}
		);
	}
	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

  @Input() set showAuthed(condition: boolean) {
		this.condition = condition;
	}
}
