import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { JwtService } from './jwt.service';

@Injectable()
export class AdminService {
	constructor(
		private apiService: ApiService,
		private jwtService: JwtService,
		private userService: UserService
	) { }

	getDetailsByAdmin(data) {
		const route = '/getDetailsByAdmin';
		data['usernameAdmin'] = this.jwtService.getUsername();
		return this.apiService.put(`${environment.admin_url}${route}`, data);
	}

	getDetailsByBD(data) {
		const route = '/getDetailsByBD';
		data['usernameAdmin'] = this.jwtService.getUsername();
		return this.apiService.put(`${environment.admin_url}${route}`, data);
	}

	updateRegistrationDetails(data) {
		const route = '/updateRegistrationDetails';
		data['usernameAdmin'] = this.jwtService.getUsername();
		return this.apiService.put(`${environment.admin_url}${route}`, data);
	}

	registerUsers(data) {
		const route = '/registerUsers';
		return this.apiService.put(`${environment.admin_url}${route}`, data);
	}

	deregisterUsers(data) {
		const route = '/deregisterUsers';
		return this.apiService.put(`${environment.admin_url}${route}`, data);
	}

	getBatchFee(batchId: number, username: string) {
		const route = '/getBatchFee';
		const data = {
			'batchId': batchId,
			'username': username
		};
		return this.apiService.post(`${environment.admin_url}${route}`, data);
	}

	updateCollegeBatchDetails(data) {
		const route = '/updateCollegeBatchDetails';
		data['username'] = this.jwtService.getUsername();
		return this.apiService.put(`${environment.admin_url}${route}`, data);
	}

	getInvalidStaticDataInfo() {
		const route = '/adminStaticData/invalidDataInfo';
		return this.apiService.get(`${environment.admin_url}${route}`);
	}

	getValidStaticDataInfo() {
		const route = '/adminStaticData/validDataInfo';
		return this.apiService.get(`${environment.admin_url}${route}`);
	}

	correctInvalidData(data) {
		const route = '/adminStaticData/correctInvalidData';
		return this.apiService.put(`${environment.admin_url}${route}`, data);
	}

	shiftBatch(data) {
		const route = '/shiftBatch';
		return this.apiService.put(`${environment.admin_url}${route}`, data);
	}

	cancelShiftBatch(data) {
		const route = '/cancelShiftBatch';
		return this.apiService.put(`${environment.admin_url}${route}`, data);
	}

	updateRefundRequest(data) {
		const route = '/updateRefundRequest';
		return this.apiService.put(`${environment.admin_url}${route}`, data);
	}

	loginAsUser(data) {
		const route = '/loginAsUser';
		return this.apiService.post(`${environment.admin_url}${route}`, data).pipe(
			map(
				(data) => {
					this.userService.purgeAuth();
					this.userService.setAuth(data.data.user);
					this.userService.populate();

					return data;
				}
			));
	}
}
