import { Component, OnInit, HostListener } from '@angular/core';
import { UserService, JwtService } from '../../shared/services';
import { take } from 'rxjs/operators';

@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
	@HostListener('document:keydown.escape', ['$event']) closeBanner(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			this.showBanner = false;
		}
	}
	showBanner = false;
	banner = {};
	key = 'BANNER';
	timeToClose = 10000;
	arr = Array.from(Array(20).keys());

	constructor(private userService: UserService, private jwtService: JwtService) { }

	ngOnInit(): void {
		this.userService.getBannerDetails()
			.pipe(take(1))
			.subscribe((resp) => {
				if (resp.data.showBanner && !this.jwtService.getKey(this.key)) {
					this.showBanner = resp.data.showBanner;
					this.banner = resp.data.banner;
					this.jwtService.setKey(this.key, true);
					// setTimeout(() => { this.showBanner = false }, this.timeToClose)
				}
			}, (err) => {
			});
	}
}
