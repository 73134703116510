import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { HelpersService } from './index';
import { BehaviorSubject } from 'rxjs';
import { take, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import axios from 'axios';

@Injectable()
export class MockService {
	private dashboardUrl = `${environment.api_url}/dashboard`;
	private mockSubject = new BehaviorSubject<any[]>([]);
	mock$ = this.mockSubject.asObservable();

	constructor(
		private apiService: ApiService,
		private helpers: HelpersService,
		private toastr: ToastrService,
	) { }

	getMocks() {
		const route = '/mockVideos';
		return this.apiService.get(`${this.dashboardUrl}${route}`);
	}

	getMockUploadURL(mockData) {
		const route = '/getUploadURL';
		this.helpers.showLoader();
		return this.apiService.post(`${this.dashboardUrl}${route}`, mockData);
	}

	async uploadMock(file: File, url: string, mockData) {
		try {
			const formData: FormData = new FormData();
			formData.append(file.name, file);
			await axios.put(url, formData, {
				headers: {
					'Access-Control-Allow-Origin': '*'
				}
			});
			/** Upload Success */
			const route = '/uploadUpdate';
			this.apiService.post(`${this.dashboardUrl}${route}`, mockData)
				.pipe(take(1), finalize(() => this.helpers.hideLoader()))
				.subscribe((res) => {
					this.mockSubject.next(res.data)
					this.toastr.success('Mock Uploaded.');
				}, (err) => {
					this.toastr.error('Some Error Occurred');
				});
		} catch (err) {
			this.helpers.hideLoader();
			this.toastr.error('Some Error Occurred');
		}
	}
}
