<div class="auth-page">
	<mat-card class="otp-container mat-elevation-z0">
		<form [formGroup]="otpForm" fxLayout="column" fxLayoutGap="10px">

			<ng-container *ngIf="otpType == 'forgot-password'">

				<mat-radio-group fxFlex="0 0 auto" class="radio-group" (change)="emailOrMobileSelection($event)"
					fxLayout="row" fxLayoutAlign="space-between center">
					<mat-radio-button fxFlex value="Mobile" checked="true">Mobile</mat-radio-button>
					<mat-radio-button fxFlex value="Email">Email</mat-radio-button>
				</mat-radio-group>

				<ng-container *ngIf="emailOrMobile=='Email'">
					<mat-form-field appearance="outline" fxFlex>
						<mat-label>Email</mat-label>
						<input matInput name="email_mobile" formControlName="email" type="text" maxlength="254" />
						<mat-error>{{getEmailErrorMessage()}}</mat-error>
					</mat-form-field>
				</ng-container>

				<ng-container *ngIf="emailOrMobile=='Mobile'">
					<div fxFlex="100%" fxLayout="row" fxLayoutGap="5px">
						<mat-form-field appearance="outline" fxFlex="0 0 100px">
							<mat-select #cc formControlName="cc"
								(selectionChange)="setCCValidator(otpForm.controls.mobile, cc)">
								<mat-option *ngFor="let cc of countriesList" [value]="cc">
									{{cc}} (+{{getCountryCallingCode(cc)}})
								</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field appearance="outline" fxFlex>
							<mat-label>Mobile</mat-label>
							<input #mobile matInput name="email_mobile" formControlName="mobile" type="text"
								(keyup)="asYouType(mobile, cc, otpForm.controls.mobile)"
								(keydown)="helpers.onlyNumbers($event)" maxlength="15" />
							<mat-error>{{getMobileErrorMessage()}}</mat-error>
						</mat-form-field>
					</div>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="otpType == 'verifyotp'">
				<h5>An OTP has been sent to you</h5>
				<mat-form-field appearance="outline">
					<mat-label for="otp-input">Enter the OTP</mat-label>
					<input matInput id="otp-input" maxlength="6" (input)="otpSubmitError = ''"
						(keydown)="helpers.onlyNumbers($event);" formControlName="otpValue" placeholder="OTP"
						type="text" id="autofocus" />
				</mat-form-field>
			</ng-container>
			<div class="button-container">

				<p class="error-msg">{{otpSubmitError}}</p>
				<button mat-flat-button color="primary" (click)="submitForm($event);">
					{{title}}
				</button>
				<div class="resend-button-container" *ngIf="otpType=='verifyotp'"><a style="cursor: pointer"
						(click)="resendOtp();">Resend OTP</a></div>
			</div>

		</form>
	</mat-card>
</div>