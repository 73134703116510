import { Component, Input } from '@angular/core';

@Component({
	selector: 'company-card',
	templateUrl: './companycard.component.html',
	styleUrls: ['./cards.component.scss']
})
export class CompanycardComponent {
	// Input the logo passed from the home component.
	@Input() logo: any;
	// abc: any = this.logo;
	// logoObj: Object = {'alt':this.logo.split(',')[0], 'imageUrl':this.logo };
}
