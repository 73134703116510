import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-username',
	templateUrl: './username.component.html',
	styleUrls: ['./username.component.scss']
})
export class UsernameComponent implements OnInit {
	@Input() username: string;
	@Input() publicView: boolean;
	@Input() link: boolean;
	@Input() dpImageFile: string;
	resizeImage = '';
	defaultPath = 'https://imagedelivery.net/L-RRwTsGtjw4XmStu9Y7XA/3d79177e-f3c7-4f69-2ebc-34520de93600/1080p';

	constructor() { }

	ngOnInit(): void {
		/**
		 * Take https://<s2-url>/<bucket-name>/<image-name>.png
		 * Split to imageFile = ['https:', '', 's3-url', 'bucket-name', 'image-name.png']
		 *
		 * name = ['image-name', 'png']
		 *
		 * Change to https://<s2-url>/<bucket-name>/<image-name>-25x25.png
		 */
		if (this.dpImageFile) {
			const imageFile = this.dpImageFile.split('/');
			const name = imageFile[4].split('.');
			this.resizeImage = imageFile[0] + '//' + imageFile[2] + '/' + imageFile[3] + '/' + name[0] + '-25X25.' + name[1];
		}
	}
}
