<mat-toolbar color="primary" class="top mat-elevation-z5">
	<a href="/">
		<div class="logo-wrapper">
			<img class="navbar-logo"
				src="https://imagedelivery.net/jP_2Cu8opO0otIERyhqaNQ/6ae91311-9b06-4e0a-1758-19b4df5f0500/public" />
		</div>
	</a>

	<div *showAuthed="false" style="display: flex;">
		<button mat-raised-button *ngIf="showButton && upcomingCodingBatchData.received " (click)="registerHandler()"
			class="register-button">
			Register Now
		</button>
		<div class="login" *ngIf="showButton">
			<a mat-button color="primary" [routerLink]="['login']">
				Login
			</a>
		</div>
	</div>

	<div class="profile" *showAuthed="true">
		<div style="display: flex;">
			<button mat-raised-button *ngIf="showButton && upcomingCodingBatchData.received && !isRegistered"
				(click)="registerHandler()" class="register-button">
				Register Now
			</button>

			<button mat-button class="user" [matMenuTriggerFor]="options">

				<img class="profile-pic" [src]="profilePictureURL" alt="" />

				<span class="username" *ngIf="!helperService.detectMobile()">{{displayName}}</span>
				<mat-icon matSuffix>arrow_drop_down</mat-icon>
			</button>
		</div>


		<mat-menu #options="matMenu" [yPosition]="'below'" [overlapTrigger]="false">
			<button mat-menu-item [routerLink]="['/profile', currentUser.username]">
				<mat-icon>person</mat-icon>
				My Profile
			</button>
			<button mat-menu-item *ngIf="isRegistered" [routerLink]="['/dashboard']">
				<mat-icon>dashboard</mat-icon>
				Dashboard
			</button>
			<button mat-menu-item [routerLink]="['/leaderboard']">
				<mat-icon>leaderboard</mat-icon>
				Leaderboard
			</button>
			<button mat-menu-item [matMenuTriggerFor]="settings">
				<mat-icon>settings</mat-icon>
				Settings
			</button>
			<button *ngIf="showAdministrationMenu" mat-menu-item [matMenuTriggerFor]="administration">
				<mat-icon aria-hidden="false">admin_panel_settings</mat-icon>
				Administration
			</button>
			<button mat-menu-item (click)="removeUser()">
				<mat-icon aria-hidden="false">logout</mat-icon>
				Logout
			</button>
		</mat-menu>
		<mat-menu #settings="matMenu">
			<button mat-menu-item [routerLink]="['/editprofile/personal']">Personal details</button>
			<button mat-menu-item [routerLink]="['/editprofile/professional']">Professional details</button>
			<button mat-menu-item [routerLink]="['/editprofile/codingDetails']">Coding profiles</button>
			<button mat-menu-item [routerLink]="['/editprofile/changePassword']">Change password</button>
		</mat-menu>
		<mat-menu #administration="matMenu">
			<button mat-menu-item [routerLink]="['/admin/staticData']">Static Data</button>
		</mat-menu>
	</div>
</mat-toolbar>
<div id="loader" *ngIf="showLoader" class="loader">
	<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>