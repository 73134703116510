import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { OtpComponent } from './otp.component';
import { SharedModule } from '../shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgMaterialModule } from '../shared/ng-material/ng-material.module';

const otpRouting = RouterModule.forRoot([
	{
		path: 'forgot-password',
		component: OtpComponent
	},
	{
		path: 'verifyotp',
		// canActivate : [AuthGuard],
		component: OtpComponent
	}
], { relativeLinkResolution: 'legacy' });

@NgModule({
	imports: [
		otpRouting,
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		NgMaterialModule,
		FlexLayoutModule
	],
	declarations: [
		OtpComponent
	],

	providers: [
	]
})
export class OtpModule { }
