import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
	public message: string;
	public state: any;
	constructor(private router: Router) {
		this.state = this.router.getCurrentNavigation().extras.state;
	}

	ngOnInit() {
		if (this.state) {
			this.message = this.state.message;
		}
	}
}
