import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgMaterialModule } from './ng-material/ng-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ListErrorsComponent } from './list-errors.component';
import { ShowAuthedDirective } from './show-authed.directive';
import { ConfirmDialogComponent } from './services/confirm-dialog/confirm-dialog.component';
import { ContactFormModule } from './contact-form/contact-form.module';
import { CheckScoreDialog } from './checkscore-dialog/checkscore-dialog.component';
import { CodingDetailsDialog } from './codingdetailsdialog/codingdetailsdialog.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FooterComponent } from './layout/footer.component';
import { RankChartComponent } from './rank-chart/rank-chart.component';
import { UsernameComponent } from './username/username.component';

import { NotifyUpcomingBatchDialogComponent } from '../courses/notify-upcoming-batch-dialog/notify-upcoming-batch-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		RouterModule,
		NgMaterialModule,
		FlexLayoutModule,
		ContactFormModule
	],
	declarations: [
		ListErrorsComponent,
		UsernameComponent,
		ShowAuthedDirective,
		ConfirmDialogComponent,
		CheckScoreDialog,
		CodingDetailsDialog,
		ContactUsComponent,
		FooterComponent,
		RankChartComponent,
		NotifyUpcomingBatchDialogComponent
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		ListErrorsComponent,
		UsernameComponent,
		FooterComponent,
		ContactUsComponent,
		RouterModule,
		ShowAuthedDirective,
		ContactFormModule,
		RankChartComponent
	],
	entryComponents: [
		ConfirmDialogComponent,
		CheckScoreDialog,
		CodingDetailsDialog,
		ContactFormModule,
		RankChartComponent
	]
})
export class SharedModule { }
