import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {
	parsePhoneNumberFromString,
	getCountries,
	getCountryCallingCode as getCountryCallingCodeImported
} from 'libphonenumber-js/max';

import { Errors, UserService, HelpersService, JwtService } from '../shared';
import * as regex from './variables';
import { PasswordValidation } from '../shared/validators/password-validation';
import { ValidateMobile } from '../shared/validators/mobile-validation';
import { message } from '../shared/messages/messages';
import { environment } from '../../environments/environment';
declare const dataLayer: any;

@Component({
	selector: 'auth-page',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss']
})
// tslint:disable: max-line-length
export class AuthComponent implements OnInit, AfterViewInit {
	progressValue: number;
	@ViewChild('captchaRef', { static: true }) captchaRef: any;

	constructor(
		private _route: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		private fb: FormBuilder,
		private toastr: ToastrService,
		private helpers: HelpersService,
		private jwtService: JwtService
	) {
		// use FormBuilder to create a form group
		this.authForm = this.fb.group({
			'username': ['', Validators.required],
			'password': ['', Validators.required]
		});

		// this.paam = this.route.snapshot.paramMap.get('b');
		// console.log(this.paam);
	}

	paam: any;
	authType: String = '';
	title: String = '';
	errors: Errors = new Errors();
	isSubmitting = false;
	authForm: FormGroup;
	messageAuth = message;
	usernameExists;
	searchParams: any;
	passwordScore = 0;
	SITE_KEY: string | undefined;
	showSpinner: boolean = false;

	emailMobileExists = false;

	emailOrMobile = 'Mobile';
	email_mobile_maxlength: any;
	passwordStrength: String = '';
	captchaResponse: String = '';
	pass: string;

	validationMessage: string;

	batchIdGiven: boolean;
	batchId;
	countriesList = [];
	surveyOptionsList: [] = [];
	isPrivacyPolicyAccepted = false;

	ngAfterViewInit() {
		setTimeout(() => this.helpers.autofocus(), 0);
		this.authForm.valueChanges.subscribe((value) => {
			if (this.authType != 'login') {
				this.captchaRef.reset();
			}
		});
	}

	checkUserExisting(event) {
		if (this.authType === 'login' || event.target.value.length < 5) {
			return;
		}
		this.checkUserExist({
			'field': 'un',
			'value': this.authForm.value.username
		});
	}

	isAgreedToPrivacyPolicy(event: any) {
		this.isPrivacyPolicyAccepted = event.checked;
	}

	getUsernameErrorMessage() {
		return this.authForm.controls['username'].errors == null ? '' :
			this.authForm.controls['username'].errors.required ? this.messageAuth.signUpForm.required :
				(this.authType === 'register' && this.authForm.controls['username'].errors.minlength) ?
					this.messageAuth.signUpForm.user.minLen :
					(this.authType === 'register' && this.authForm.controls['username'].errors.maxlength) ?
						this.messageAuth.signUpForm.user.maxLen :
						(this.authForm.controls['username'].errors.pattern) ? this.messageAuth.signUpForm.user.pattern :
							(this.authType === 'register' && this.usernameExists) ? 'Username already exists' :
								'';
	}

	getMobileErrorMessage() {
		return this.authForm.controls['mobile'].errors === null ? '' :
			this.authForm.controls['mobile'].errors.required ? this.messageAuth.signUpForm.required :
				this.authForm.controls['mobile'].errors.exists ? this.messageAuth.signUpForm.mobileOrEmail.mobileExists :
					this.authForm.controls['mobile'].errors ? this.authForm.controls['mobile'].errors.message :
						'';
	}

	getPasswordErrorMessage() {
		return this.authForm.controls['password'].errors === null ? '' :
			this.authForm.controls['password'].errors.required ? this.messageAuth.signUpForm.required :
				(this.authType === 'register' && this.authForm.controls['password'].errors.minlength) ?
					this.messageAuth.signUpForm.password.minLen :
					'';
	}
	getConfirmPasswordErrorMessage() {
		return this.authForm.controls['confirmpassword'].errors === null ? '' :
			this.authForm.controls['confirmpassword'].errors.required ?
				this.messageAuth.signUpForm.required :
				this.authForm.controls['confirmpassword'].errors.MatchPassword ?
					this.messageAuth.signUpForm.password.notMatching :
					'';
	}

	checkEmailMobile(event) {
		const val = parsePhoneNumberFromString(event.target.value, this.authForm.controls['cc'].value);
		if (val && val.isValid()) {
			this.checkIfAlreadyExisting('m', val.number, this.authForm.controls['cc'].value);
		}
	}

	checkIfAlreadyExisting(type: string, value: string, cc = null) {
		const a = {};
		a['field'] = type;
		a['value'] = value;
		a['cc'] = cc;
		this.userService.checkVerifiedUser(a).pipe(take(1)).subscribe((data) => {
			if (data.status) {
				this.emailMobileExists = true;
				this.authForm.controls['mobile'].setErrors({ 'exists': true });
			} else {
				this.emailMobileExists = false;
				this.authForm.controls['mobile'].setErrors(null);
			}
		}, (err) => {
			this.emailMobileExists = false || this.emailMobileExists;
		});
	}

	checkUserExist(a) {
		this.userService.checkUser(a).pipe(take(1)).subscribe((data) => {
			if (data.status) {
				this.usernameExists = true;
				this.authForm.controls['username'].setErrors({ 'exists': true });
			} else {
				this.usernameExists = false;
			}
		}, (err) => {
			// console.log(err);
		});
	}

	onPassword(event) {
		this.pass = event.target.value;
		this.passwordScore = 0;
		if (this.pass.length >= 6) {
			const letters = {};
			for (let i = 0; i < this.pass.length; i++) {
				letters[this.pass[i]] = (letters[this.pass[i]] || 0) + 1;
				this.passwordScore += 5.0 / letters[this.pass[i]];
			}

			const variations = {
				digits: /\d/.test(this.pass),
				lower: /[a-z]/.test(this.pass),
				upper: /[A-Z]/.test(this.pass),
				nonWords: /\W/.test(this.pass)
			};

			let variationCount = 0;
			for (const check in variations) {
				if (variations[check] === true) {
					variationCount += 1;
				}
			}
			this.passwordScore += (variationCount - 1) * 10;
			this.passwordStrength = this.passwordScore + '';
			if (this.passwordScore >= 80) {
				this.passwordStrength = this.messageAuth.signUpForm.password.strong;
				this.progressValue = 100;
			} else if (this.passwordScore >= 56) {
				this.passwordStrength = this.messageAuth.signUpForm.password.average;
				this.progressValue = 60;
			} else {
				this.passwordStrength = this.messageAuth.signUpForm.password.weak;
				this.progressValue = 30;
			}
		}
	}

	getCountryCallingCode = getCountryCallingCodeImported;

	ngOnInit() {
		this.userService.getSurveyOptionsList().pipe(take(1)).subscribe((result) => {
			if (result.status) {
				this.surveyOptionsList = result.data;
			}
		});

		this._route.queryParams.pipe(take(1)).subscribe((data) => {
			this.batchIdGiven = data['batch'] != null;
			this.batchId = data['batch'];
		});
		this.countriesList = getCountries();
		this.validationMessage = '';
		this.usernameExists = false;
		this.emailOrMobile = 'Mobile';
		this.SITE_KEY = environment.recaptcha.siteKeyV2;
		this._route.url.pipe(take(1)).subscribe((data) => {
			// Get the last piece of the URL (it's either 'login' or 'register')
			this.authType = data[data.length - 1].path;
			// Set a title for the page accordingly
			this.title = (this.authType === 'login') ? 'Log In' : 'Sign Up';
			// add form control for username if this is the register page
			this.setAuth(this.authType);
		});
		this.authForm.controls['username'].errors['usernameExists'] = false;
	}

	/**
	 * Overwrites the input with the formatted number
	 * @param {HTMLInputElement} field
	 * @param {MatSelect} cc country code
	 */
	public asYouType(field, cc, formControl): void {
		const parsedNumber = parsePhoneNumberFromString(field.value, cc.selected.value);
		if (parsedNumber) {
			this.setCCValidator(formControl, cc);
			field.value = parsePhoneNumberFromString(field.value, cc.selected.value).nationalNumber;
			formControl.setValue(field.value);
		}
	}

	public setCCValidator(formControl: FormControl, cc): void {
		formControl.clearValidators();
		formControl.setValidators([Validators.required, ValidateMobile(cc.selected.value)]);
		formControl.markAsTouched({ onlySelf: true });
		formControl.markAsDirty({ onlySelf: true });
		formControl.updateValueAndValidity();
	}

	submitForm(formInvalid: boolean) {
		if (formInvalid) {
			this.helpers.validateAllFields(this.authForm);
			return;
		}

		if (this.helpers.isLoading) {
			return;
		}
		this.helpers.showLoader();
		const data = this.authForm.value;
		data.recaptcha = this.captchaResponse;

		if (this.authType === 'register') {
			data.isPrivacyPolicyAccepted = this.isPrivacyPolicyAccepted;
		}

		this.userService.attemptAuth(this.authType, data)
			.pipe(take(1), finalize(() => this.helpers.hideLoader()))
			.subscribe(
				(resp) => {
					if (resp.status) {
						if (resp.data.isAccountVerified) {
							this._route.queryParams.pipe(take(1)).subscribe((param) => {
								if (resp.data.maliciousFlag && !resp.data.maliciousSafe) {
									const alertMessage =
										`Your profile has been flagged due to possibly malicious username(s)
									in your coding profiles. You are requested to rectify it immediately and wait for
									at least 24 hours for the scores to reflect.`;
									this.toastr.error(alertMessage, '', {
										disableTimeOut: true,
										positionClass: 'toast-top-full-width'
									});
								}
								if (param.returnUrl === '/smart-coder') {
									this.router.navigate([param.returnUrl], { fragment: 'batches' });
								} else if (param.returnUrl) {
									this.router.navigate([param.returnUrl]);
								} else {
									this.router.navigate(['/profile/' + resp.data.username]);
								}
								dataLayer.push({ 'event': this.authType === 'login' ? 'login' : 'signup' });
							});
						} else {
							if (resp.data.attempts === -1) {
								this.validationMessage = this.messageAuth.otp.tryAgain;
								this.toastr.error(this.validationMessage);
							} else {
								this.jwtService.userTemp = resp.data.email || resp.data.mobile;
								this.jwtService.type = resp.data.email ? 'email' : 'mobile';
								this.router.navigate(['/verifyotp'], { queryParamsHandling: 'preserve' });
							}
						}
					} else {
						this.validationMessage = resp.message;
						this.toastr.error(this.validationMessage);
					}
					return;
				},
				(err) => {
					this.validationMessage = err.message;
					this.toastr.error(this.validationMessage);
				}
			);
	}

	setAuth(authType: String) {
		if (authType === 'register') {
			this.authForm.addControl('cc', new FormControl('IN', [Validators.required]));
			this.authForm.addControl('mobile', new FormControl('', [Validators.required, ValidateMobile()]));
			this.authForm.addControl('confirmpassword', new FormControl('', [Validators.required]));
			this.authForm.validator = PasswordValidation.MatchPassword;
			this.authForm.controls['username'].setValidators([Validators.required, Validators.minLength(6),
			Validators.maxLength(16), Validators.pattern(regex.usernameregex)]);
			this.authForm.controls['password']
				.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(15)]);
			this.authForm.addControl('surveyOptions', new FormControl('', [Validators.required]));
		} else {
			this.authForm.controls['username'].setValidators([Validators.required, Validators.minLength(6),
			Validators.maxLength(16), Validators.pattern(regex.usernameregex)]);
		}
	}

	recaptchaResolved(captchaResponse) {
		if (!captchaResponse) {
			return;
		}
		this.captchaResponse = captchaResponse;
		this.submitForm(this.authForm.invalid);
	}

	/**
	 * checks if authForm is valid and calls captchaRef.execute()
	 */
	handleSignUp() {
		if (this.authForm.invalid) {
			this.helpers.validateAllFields(this.authForm);
			return;
		}

		this.showSpinner = true;
		setTimeout(() => {
			this.showSpinner = false;
		}, 4000);

		this.captchaRef.execute();
	}
}
