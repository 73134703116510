import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService, HelpersService } from '../../shared';
import { finalize, tap, distinctUntilChanged, take } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Chart } from 'chart.js';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
	constructor(
		private route: ActivatedRoute,
		private helpers: HelpersService,
		private router: Router,
		private toastr: ToastrService,
		private userService: UserService
	) { }

	private userSubject = new Subject<any>();
	user$ = this.userSubject.asObservable().pipe(distinctUntilChanged());

	public chart: any;
	public displayChart: any = false;
	public ranksData: any[] = [];
	public rankChart: any;
	public scoreChart: any;
	public latestGlobalRank;

	display: string;
	/** obj stores all observables and unsubscribes them onDestroy */
	private obj: Subscription = new Subscription();

	/** Default image url */
	defaultPath = 'https://imagedelivery.net/jP_2Cu8opO0otIERyhqaNQ/71854130-0d5f-4028-37ce-35dfd6007500/public';
	profilePictureURL: String;

	ngOnInit(): void {
		this.fetchUser();
	}

	ngAfterViewInit(): void {
		/** Watching for route change to fetch another profile */
		this.obj.add(this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.fetchUser();
			}
		}));
	}

	ngOnDestroy(): void {
		this.obj.unsubscribe();
		this.userSubject.unsubscribe();
	}

	/** Fetches user profile */
	private fetchUser(): void {
		this.helpers.showLoader();
		const username = this.route.snapshot.params['username'];
		this.userService.fetchProfile(username)
			.pipe(
				take(1),
				tap((val) => { }, (err) => {
					this.router.navigate(['/404'], { skipLocationChange: true });
				}),
				finalize(() => {
					this.helpers.hideLoader();
				})
			).subscribe((resp) => {
				this.userSubject.next(resp);

				/** If any previous chart is existing, destroy it first */
				if (this.chart) {
					this.chart.destroy();
					this.chart = null;
				}
				if (!resp.data.rank || !resp.data.rank.ranks) {
					return;
				}

				/** max and min rank used to mark graph lower and upper bounds */
				let maxRank = 0; let minRank = 99999999;
				/** Consolidate dates[] and ranks[] for plotting the graph */
				const dates = resp.data.rank.ranks.map((ele) => {
					const date = new Date(ele.date);
					return date.toLocaleDateString('en', { year: 'numeric', month: 'short', day: 'numeric' });
				});
				const ranks = resp.data.rank.ranks.map((ele) => {
					maxRank = Math.max(maxRank, ele.rank);
					minRank = Math.min(minRank, ele.rank);
					return ele.rank;
				});

				if (ranks.length >= 1) {
					this.latestGlobalRank = ranks[ranks.length - 1];
				}
				/** Add 10% extra space on lower bound and upper bound */
				maxRank += ((maxRank * 10) / 100);
				minRank -= ((minRank * 10) / 100);

				/** ID of the canvas element is the context(ctx) */
				this.chart = new Chart('rank-chart', {
					type: 'line',
					data: {
						labels: dates,
						datasets: [
							{
								data: ranks,
								borderColor: '#673ab7',
								pointBackgroundColor: '#673ab7',
								pointBorderColor: 'rgba(103, 58, 183, 0.2)',
								fill: false,
								lineTension: 0.5,
								pointRadius: 0,
								pointHitRadius: 8
							}
						]
					},
					options: {
						legend: {
							display: false
						},
						tooltips: {
							position: 'nearest',
							intersect: false
						},
						scales: {
							xAxes: [{
								display: true,
								/** Modify the axes tick labels to display only relevant information */
								afterTickToLabelConversion: function (data) {
									const xLabels = data.ticks;
									/** To decide the size of gap to maintain on x-axis to fit max 4 labels */
									const gap = Math.round(Math.max(xLabels.length / 4, 1));
									xLabels.forEach((label, i) => {
										if (i % gap !== 0) {
											xLabels[i] = '';
										} else {
											/** Strip day from date */
											const tempDate = new Date(label);
											xLabels[i] = tempDate.toLocaleDateString('en',
												{ year: 'numeric', month: 'short' });
										}
									});
								},
								ticks: {
									/** Prevent rotation of labels */
									autoSkip: false,
									maxRotation: 0,
									minRotation: 0
								},
								gridLines: {
									/** Hide x-axis grid */
									drawOnChartArea: false
								}
							}],
							yAxes: [{
								display: true,
								ticks: {
									/** Set lower and upper bounds */
									suggestedMax: maxRank,
									suggestedMin: minRank,
									/** Do not allow floating point numbers */
									precision: 0,
									/** Display in reverse because lower rank means better performance */
									reverse: true
								}
							}]
						}
					}
				});
				const scoreLabel = ['HackerRank', 'SmartInterviews', 'LeetCode', 'InterviewBit', 'CodeChef', 'Codeforces', 'Spoj'];
				const scoreSubSetLabel = ['DS', 'Algo', 'Basic', 'Primary', 'Problems', 'Rating', 'Problems', 'Problems', 'Rating', 'Problems', 'Rating', 'Problems', 'Points'];
				const scores = [
					resp.data.individualScores.hr,
					resp.data.individualScores.si,
					resp.data.individualScores.lc,
					resp.data.individualScores.ib,
					resp.data.individualScores.cc,
					resp.data.individualScores.cf,
					resp.data.individualScores.spoj
				];
				const scoreSubSet = [
					resp.data.individualScores.hrds,
					resp.data.individualScores.hralgo,
					resp.data.individualScores.siBasic,
					resp.data.individualScores.siPrimary,
					resp.data.individualScores.lcP,
					resp.data.individualScores.lcR,
					resp.data.individualScores.ib,
					resp.data.individualScores.ccP,
					resp.data.individualScores.ccR,
					resp.data.individualScores.cfP,
					resp.data.individualScores.cfR,
					resp.data.individualScores.spojPr,
					resp.data.individualScores.spojPo
				];
				this.scoreChart = new Chart('score-chart', {
					type: 'doughnut',

					data: {
						labels: scoreLabel,
						datasets: [{

							data: scores,
							backgroundColor: ['#00B26B', '#FFD30E', '#7E3517', '#0E51A7', '#0EB2FF', '#800080', '#B3430A']
						},
						{
							data: scoreSubSet,
							backgroundColor: ['#7DFFCB', '#00FF99', '#FFE469', '#FFDC3F', '#C36241', '#FF9C5B', '#87CEEB', '#39BEFF', '#66CCFD', '#B666D2', '#F9B7FF', '#EF7E3E', '#DB580E']
						}
						]
					},
					options: {
						responsive: true,
						legend: {
							onClick: null,
							display: true,
							position: 'right'

						},
						tooltips: {
							callbacks: {
								label: function (tooltipItem, data) {
									if (tooltipItem.datasetIndex == 0) {
										return scoreLabel[tooltipItem.index] + ' : ' + data.datasets[0].data[tooltipItem.index];
									} else {
										return scoreSubSetLabel[tooltipItem.index] + ' : ' + data.datasets[1].data[tooltipItem.index];
									}
								}
							}
						}
					}
				});
			});
	}

	updatePrivacy(event): void {
		if (this.helpers.isLoading) {
			return;
		}
		this.helpers.showLoader();
		const data = {
			privacy: event.source.checked
		};
		this.obj.add(this.userService.updatePrivacy(data)
			.pipe(finalize(() => this.helpers.hideLoader()))
			.subscribe(
				(resp) => this.toastr.success('User details updated successfully'),
				(err) => this.toastr.error('Error while updating user details')
			));
	}
}
