import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { NgMaterialModule } from '../shared/ng-material/ng-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { UsersRoutingModule } from './users-routing.module';
import { SharedModule } from '../shared';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

@NgModule({
	imports: [
		CommonModule,
		NgMaterialModule,
		FlexLayoutModule,
		UsersRoutingModule,
		SharedModule
	],
	exports: [
		UsersRoutingModule
	],
	declarations: [ProfileComponent, LeaderboardComponent, UnsubscribeComponent]
})
export class UsersModule { }
