import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { AuthGuard } from '../shared';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

const routes: Routes = [
	{ path: 'profile/:username', component: ProfileComponent },
	{
		path: 'leaderboard',
		children: [
			{
				path: 'batch/:batchName',
				canActivate: [AuthGuard],
				children: [
					/** If pageIndex is not found, redirecTo page 1 */
					{ path: '', redirectTo: '1', pathMatch: 'full' },
					{ path: ':pageIndex', component: LeaderboardComponent }
				]
			},
			/** If pageIndex is not found, redirecTo page 1 */
			{ path: '', redirectTo: '1', pathMatch: 'full' },
			{ path: ':pageIndex', component: LeaderboardComponent }
		]
	},
	{ path: 'unsubscribe/:referrerId', component: UnsubscribeComponent }
];


@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [RouterModule]
})

export class UsersRoutingModule { }
