import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { finalize, take } from 'rxjs/operators';
import { getCountries, getCountryCallingCode as getCountryCallingCodeImported, parsePhoneNumberFromString } from 'libphonenumber-js/max';

import { UserService, HelpersService } from '../../shared';
import { ValidateMobile } from '../../shared/validators/mobile-validation';
import { JwtService } from '../../shared/services/jwt.service';

@Component({
	selector: 'app-notify-upcoming-batch-dialog',
	templateUrl: './notify-upcoming-batch-dialog.component.html',
	styleUrls: ['./notify-upcoming-batch-dialog.component.scss']
})
export class NotifyUpcomingBatchDialogComponent implements OnInit {
	notifyMeForm: FormGroup;
	countriesList = [];
	notifyMeData = {
		fullname: '',
		mobile: '',
		email: '',
		cc: 'IN',
		batchName: ''
	};
	getCountryCallingCode = getCountryCallingCodeImported;

	constructor(
		private fb: FormBuilder,
		private jwtService: JwtService,
		private userService: UserService,
		private toastr: ToastrService,
		public helpers: HelpersService,
		public dialogRef: MatDialogRef<NotifyUpcomingBatchDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data

	) {
		this.notifyMeData.batchName = data.batchName;
	}


	ngOnInit(): void {
		this.countriesList = getCountries();
		if (this.jwtService.getToken()) {
			this.userService.currentUser
				.pipe(take(1))
				.subscribe((userData: any) => {
					this.notifyMeData.fullname = userData.personalDetails.name;
					this.notifyMeData.email = userData.email;
					const parsedNumber = parsePhoneNumberFromString(userData.mobile || '', 'IN');
					this.notifyMeData.mobile = parsedNumber ? parsedNumber.nationalNumber.toString() : '';
					this.notifyMeData.cc = parsedNumber ? parsedNumber.country : 'IN';
					this.createForm();
				});
		} else {
			this.createForm();
		}
	}

	createForm() {
		this.notifyMeForm = this.fb.group({
			'fullname': [this.notifyMeData.fullname, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
			'batchName': [{ value: this.notifyMeData.batchName, disabled: true }],
			'mobile': [this.notifyMeData.mobile, [Validators.required, ValidateMobile()]],
			'email': [this.notifyMeData.email, [Validators.required, Validators.email]],
			'cc': [this.notifyMeData.cc, [Validators.required]]
		});
	}


	public setCCValidator(formControl, cc): void {
		formControl.clearValidators();
		formControl.setValidators([Validators.required, ValidateMobile(cc.selected.value)]);
		formControl.markAsTouched({ onlySelf: true });
		formControl.markAsDirty({ onlySelf: true });
		formControl.updateValueAndValidity();
	}

	sendMessage() {
		this.saveNotifyMeUpcomingBatchData(this.notifyMeForm.getRawValue());
	}

	onDismiss(): void {
		this.dialogRef.close(false);
	}

	getErrorMessage(controlName: string, groupName: FormGroup): string {
		return groupName.controls[controlName].errors == null ? '' :
			groupName.controls[controlName].errors.required ? 'Required' :
				groupName.controls[controlName].errors.minlength ? 'Please enter more characters' :
					groupName.controls[controlName].errors.maxLength ? 'Please enter less characters' :
						groupName.controls[controlName].invalid ? 'Invalid input' :
							'';
	}

	/**
	 * makes an api call to the server to store notifyUpcomingBatchData
	 * @param data { batchName, name, email, mobile, cc}
	 */
	saveNotifyMeUpcomingBatchData(data: any) {
		this.userService.saveNotifyUpcomingBatchData(data)
			.pipe(take(1), finalize(() => this.helpers.hideLoader()))
			.subscribe((res: any) => {
				if (res.status) {
					this.toastr.success(res.message);
					this.dialogRef.close(true);
				} else {
					this.toastr.error(res.message);
					this.dialogRef.close(false);
				}
			}, (err) => {
				this.toastr.error(err.message);
			});
	}

	/**
	 * Overwrites the input with the formatted number
	 * @param {HTMLInputElement} field
	 * @param {MatSelect} cc country code
	 */
	public asYouType(field, cc, formControl: any): void {
		const parsedNumber = parsePhoneNumberFromString(field.value, cc.selected.value);
		if (parsedNumber) {
			this.setCCValidator(formControl, cc);
			field.value = parsedNumber.nationalNumber;
			this.notifyMeForm.controls['cc'].setValue(parsedNumber.country);
			formControl.setValue(field.value);
		}
	}
}


