export class User {
	username: string;
	usernameCaseSensitive: string;
	email: string;
	mobile: string;
	token: string;
	isAccountVerified: boolean;
	role: string;
	roleLevel: number;
	isRegistered: boolean;
	isRegisteredCoding: boolean;
	isBelongToPhaseOne: boolean;
	personalDetails: {
		dpImageFile: string,
		resumeFile: string,
		name: string,
		gender: string,
		dob: string,
		whatsApp: string,
		social: {
			facebook: string,
			linkedin: string,
			google: string,
			quora: string,
			twitter: string,
			website: string
		},
		github: {
			username: string,
			email: string
		},
		aboutMe: string,
		address: {
			building: string,
			street: string,
			city: string,
			state: string,
			country: string,
			pin: string
		},
		tshirt: string
	};
}
