
import { throwError as observableThrowError, Observable, of, throwError } from 'rxjs';

import { catchError, retryWhen, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Config } from '../config';


import { JwtService } from './jwt.service';

@Injectable()
export class ApiService {
	constructor(
		private httpClient: HttpClient,
		private jwtService: JwtService
	) { }

	private setHeaders(): HttpHeaders {
		const headersConfig = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		};

		if (this.jwtService.getToken()) {
			headersConfig['authorization'] = `Token ${this.jwtService.getToken()}`;
			headersConfig['username'] = `${this.jwtService.getUsername()}`;
			headersConfig['role'] = `${this.jwtService.getRole()}`;
		}

		/** add if there any referral related things (refId, rc) */
		if (this.jwtService.getRC()) {
			headersConfig['rc'] = this.jwtService.getRC();
		}
		return new HttpHeaders(headersConfig);
	}

	private setFileHeaders(): HttpHeaders {
		const headersConfig = {

		};

		if (this.jwtService.getToken()) {
			headersConfig['authorization'] = `Token ${this.jwtService.getToken()}`;
			headersConfig['username'] = `${this.jwtService.getUsername()}`;
			headersConfig['role'] = `${this.jwtService.getRole()}`;
		}
		return new HttpHeaders(headersConfig);
	}

	private formatErrors(response: any) {
		return observableThrowError(response.error);
	}

	/**
	 * Retry logic based on which the POST/GET/PUT etc. calls will make a retry
	 * to the same URL.
	 *
	 * Currently failing for unknown reasons.
	 */
	private retryOnFail() {
		let retries = Config.RETRY_COUNT;
		return retryWhen((err: Observable<any>) => err.pipe(mergeMap((error) => {
			if (error.status !== 500) {
				return throwError(error);
			} else {
				return (retries-- > 0) ? of(error) : throwError(error);
			}
		})));
	}

	get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
		return this.httpClient.get<any>(
			path,
			{ headers: this.setHeaders(), params }
		).pipe(
			// this.retryOnFail(),
			catchError(this.formatErrors)
		);
	}

	put(path: string, body: Object = {}): Observable<any> {
		return this.httpClient.put<any>(
			path,
			JSON.stringify(body),
			{ headers: this.setHeaders() }
		).pipe(
			// this.retryOnFail(),
			catchError(this.formatErrors)
		);
	}

	post(path: string, body: Object = {}): Observable<any> {
		return this.httpClient.post<any>(
			path,
			JSON.stringify(body),
			{ headers: this.setHeaders() }
		).pipe(
			// this.retryOnFail(),
			catchError(this.formatErrors)
		);
	}

	postFile(path: string, file: FormData): Observable<any> {
		return this.httpClient.post<any>(
			path,
			file,
			{ headers: this.setFileHeaders() }
		).pipe(
			// this.retryOnFail(),
			catchError(this.formatErrors)
		);
	}

	delete(path, params: HttpParams = new HttpParams()): Observable<any> {
		return this.httpClient.delete(
			path,
			{ headers: this.setHeaders(), params }
		).pipe(
			// this.retryOnFail(),
			catchError(this.formatErrors)
		);
	}
}
