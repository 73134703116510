import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService, HelpersService, JwtService } from '../../shared';
import { finalize, tap, distinctUntilChanged, take } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';

@Component({
	selector: 'app-rank-chart',
	templateUrl: './rank-chart.component.html',
	styleUrls: ['./rank-chart.component.scss']
})
export class RankChartComponent implements OnInit {
	public chart: any;
	private obj: Subscription = new Subscription();

	constructor(
		private route: ActivatedRoute,
		private helpers: HelpersService,
		private userService: UserService,
		private jwtService: JwtService,
		private router: Router
	) { }

	private userSubject = new Subject<any>();
	user$ = this.userSubject.asObservable().pipe(distinctUntilChanged());

	ngOnInit(): void {
		this.fetchUser();
	}

	ngAfterViewInit(): void {
		/** Watching for route change to fetch another profile */
		this.obj.add(this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.fetchUser();
			}
		}));
	}

	private fetchUser(): void {
		this.helpers.showLoader();
		const username = this.jwtService.getUsername();
		this.userService.fetchProfile(username)
			.pipe(
				take(1),
				tap((val) => { }, (err) => {
					this.router.navigate(['/404'], { skipLocationChange: true });
				}),
				finalize(() => {
					this.helpers.hideLoader();
				})
			).subscribe((resp) => {
				this.userSubject.next(resp);

				/** If any previous chart is existing, destroy it first */
				if (this.chart) {
					this.chart.destroy();
					this.chart = null;
				}
				if (!resp.data.rank || !resp.data.rank.ranks) {
					return;
				}

				/** max and min rank used to mark graph lower and upper bounds */
				let maxRank = 0; let minRank = 99999999;
				/** Consolidate dates[] and ranks[] for plotting the graph */
				const dates = resp.data.rank.ranks.map((ele) => {
					const date = new Date(ele.date);
					return date.toLocaleDateString('en', { year: 'numeric', month: 'short', day: 'numeric' });
				});
				const ranks = resp.data.rank.ranks.map((ele) => {
					maxRank = Math.max(maxRank, ele.rank);
					minRank = Math.min(minRank, ele.rank);
					return ele.rank;
				});

				/** Add 10% extra space on lower bound and upper bound */
				maxRank += ((maxRank * 10) / 100);
				minRank -= ((minRank * 10) / 100);

				/** ID of the canvas element is the context(ctx) */
				this.chart = new Chart('rank-chart', {
					type: 'line',
					data: {
						labels: dates,
						datasets: [
							{
								data: ranks,
								borderColor: '#673ab7',
								pointBackgroundColor: '#673ab7',
								pointBorderColor: 'rgba(103, 58, 183, 0.2)',
								fill: false,
								lineTension: 0.5,
								pointRadius: 0,
								pointHitRadius: 8
							}
						]
					},
					options: {
						legend: {
							display: false
						},
						tooltips: {
							position: 'nearest',
							intersect: false
						},
						scales: {
							xAxes: [{
								display: true,
								/** Modify the axes tick labels to display only relevant information */
								afterTickToLabelConversion: function (data) {
									const xLabels = data.ticks;
									/** To decide the size of gap to maintain on x-axis to fit max 4 labels */
									const gap = Math.round(Math.max(xLabels.length / 4, 1));
									xLabels.forEach((label, i) => {
										if (i % gap !== 0) {
											xLabels[i] = '';
										} else {
											/** Strip day from date */
											const tempDate = new Date(label);
											xLabels[i] = tempDate.toLocaleDateString('en',
												{ year: 'numeric', month: 'short' });
										}
									});
								},
								ticks: {
									/** Prevent rotation of labels */
									autoSkip: false,
									maxRotation: 0,
									minRotation: 0
								},
								gridLines: {
									/** Hide x-axis grid */
									drawOnChartArea: false
								}
							}],
							yAxes: [{
								display: true,
								ticks: {
									/** Set lower and upper bounds */
									suggestedMax: maxRank,
									suggestedMin: minRank,
									/** Do not allow floating point numbers */
									precision: 0,
									/** Display in reverse because lower rank means better performance */
									reverse: true
								}
							}]
						}
					}
				});
			});
	}
}
