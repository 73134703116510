<!--
    Each testimonial card contains two sides(front and back) which is flipped on hover.
-->
<div class="flip-container">
	<div class="card">
		<div class="front">
			<!-- front side content -->
			<img src={{testimonial.image}} alt={{testimonial.name}}>
			<h3>{{testimonial.name}}</h3>
			<div class="testimonial-placement">
				<span>{{testimonial.placed}} </span>
			</div>
			<div class="social">
				<a href={{testimonial.fb}} target="_blank">
					<i class="mdi mdi-facebook mdi-24px"></i>
				</a>
				<a href={{testimonial.linkedin}} target="_blank">
					<i class="mdi mdi-linkedin mdi-24px"></i>
				</a>
			</div>
		</div>
		<div class="back">
			<figure class="quote">
				<figcaption>
					<blockquote>{{testimonial.content}}</blockquote>
				</figcaption>
				<h3>{{testimonial.name}}<span>{{testimonial.placed}}</span></h3>
			</figure>
		</div>
	</div>
</div>