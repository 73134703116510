export class CodingProfile {
	Hackerrank: '';
	Codechef: '';
	Leetcode: '';
	Codeforces: '';
	Spoj: '';
	InterviewBit: '';
	isRegistered: boolean;
	attemptsLeft: number;
}
