<div *ngIf="showBanner" class="banner-container">
	<div *ngFor="let ele of arr" class="confetti"></div>

	<div *ngFor="let ele of arr" class="confetti2"></div>

	<div class="banner-message">

		{{banner.message}}

	</div>

	<div class="close" (click)="showBanner=false">

		<mat-icon>close</mat-icon>

	</div>

</div>