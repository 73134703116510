import { Component, OnInit, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService, HelpersService } from '../services';
import { take } from 'rxjs/internal/operators/take';
import { ValidateMobile } from '../validators/mobile-validation';
import { getCountries, getCountryCallingCode as getCountryCallingCodeImported, parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { JwtService } from '../services/jwt.service';

@Component({
	selector: 'app-contact-form',
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.scss']
})

export class ContactFormComponent implements OnInit {
	constructor(
		private toastr: ToastrService,
		private dialog: MatDialog,
		private userService: UserService
	) {
	}

	ngOnInit() {
	}

	openContactDialogForm() {
		const dialogRef = this.dialog.open(ContactDialogFormComponent, {
			width: '400px'
		});

		dialogRef.afterClosed()
			.pipe(take(1))
			.subscribe((result) => {
				if (result) {
					this.userService.sendContactForm(result).pipe(take(1)).subscribe((results) => {
						if (results.status) {
							this.toastr.success('Your message has been received successfully, we will get back to you');
						}
					}, (error) => {
						this.toastr.error('Error submitting your form!');
					});
				}
			}, (error) => { });
	}
}

export interface ContactFormDetails {
	fullname: string;
	mobile: string;
	email: string;
	message: string;
	surveyOptions: string;
	countryCode: string;
}

@Component({
	selector: 'app-contact-dialog-form',
	templateUrl: './contact-dialog-form.component.html'
})

export class ContactDialogFormComponent implements OnInit {
	contactForm: FormGroup;
	countriesList = [];
	surveyOptionsList: [] = [];
	getCountryCallingCode = getCountryCallingCodeImported;
	populateEmail = '';
	populateMobile = '';
	populateName = '';
	parsedMobile = '';
	cc = 'IN';
	constructor(
		private fb: FormBuilder,
		private toastr: ToastrService,
		public dialogRef: MatDialogRef<ContactDialogFormComponent>,
		public helpers: HelpersService,
		private jwtService: JwtService,
		private userService: UserService,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		if (this.jwtService.getToken()) {
			this.userService.currentUser
				.pipe(take(1))
				.subscribe((userData) => {
					this.populateEmail = userData.email;
					this.populateMobile = userData.mobile;
					this.populateName = userData.personalDetails.name;
					const parsedNumber = parsePhoneNumberFromString(userData.mobile || '', 'IN');
					this.parsedMobile = parsedNumber ? parsedNumber.nationalNumber.toString() : '';
					this.cc = parsedNumber ? parsedNumber.country : 'IN';
				});
		}

		this.contactForm = this.fb.group({
			'fullname': [this.populateName, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
			'mobile': [this.parsedMobile, [Validators.required, ValidateMobile()]],
			'email': [this.populateEmail, [Validators.required, Validators.email]],
			'message': ['', [Validators.required, Validators.minLength(10), Validators.maxLength(250)]],
			'cc': [this.cc, [Validators.required]],
			'surveyOptions': ['', [Validators.required]]
		});
	}

	ngOnInit() {
		this.countriesList = getCountries();
		this.userService.getSurveyOptionsList().pipe(take(1)).subscribe((result) => {
			if (result.status) {
				this.surveyOptionsList = result.data;
			}
		});
	}

	getErrorMessage(controlName: string, groupName: FormGroup): string {
		return groupName.controls[controlName].errors == null ? '' :
			groupName.controls[controlName].errors.required ? 'Required' :
				groupName.controls[controlName].errors.minlength ? 'Please enter more characters' :
					groupName.controls[controlName].errors.maxLength ? 'Please enter less characters' :
						groupName.controls[controlName].invalid ? 'Invalid input' :
							'';
	}

	onDismiss(): void {
		this.dialogRef.close(false);
	}

	sendMessage() {
		const contactFormDetails: ContactFormDetails = {
			fullname: this.contactForm.controls['fullname'].value,
			mobile: this.contactForm.controls['mobile'].value,
			email: this.contactForm.controls['email'].value,
			message: this.contactForm.controls['message'].value,
			surveyOptions: this.contactForm.controls['surveyOptions'].value,
			countryCode: this.contactForm.controls['cc'].value
		};
		this.dialogRef.close(contactFormDetails);
	}

	public setCCValidator(formControl, cc): void {
		formControl.clearValidators();
		formControl.setValidators([Validators.required, ValidateMobile(cc.selected.value)]);
		formControl.markAsTouched({ onlySelf: true });
		formControl.markAsDirty({ onlySelf: true });
		formControl.updateValueAndValidity();
	}

	/**
	 * Overwrites the input with the formatted number
	 * @param {HTMLInputElement} field
	 * @param {MatSelect} cc country code
	 */
	public asYouType(field, cc, formControl: any): void {
		const parsedNumber = parsePhoneNumberFromString(field.value, cc.selected.value);
		if (parsedNumber) {
			this.setCCValidator(formControl, cc);
			field.value = parsedNumber.nationalNumber;
			this.contactForm.controls['cc'].setValue(parsedNumber.country);
			formControl.setValue(field.value);
		}
	}
}

