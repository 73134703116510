import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
	selector: 'app-checkscore-dialog',
	templateUrl: './checkscore-dialog.component.html',
	styleUrls: ['./checkscore-dialog.component.scss']
})
export class CheckScoreDialog implements OnInit {
	hidePlatforms = {
		hideHackerRank: true,
		hideLeetcode: true,
		hideCodeforces: true,
		hideSpoj: true,
		hideCodeChef: true,
		hideInterviewBit: true
	};

	invokedAtPayment: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<CheckScoreDialog>,
		@Inject(MAT_DIALOG_DATA) public data: {
			heading: string,
			body: any,
			minThreshold: number,
			isRegistered: boolean,
			phaseOne: boolean,
			hidePlatforms: {
				hideHackerRank: boolean,
				hideLeetcode: boolean,
				hideCodeforces: boolean,
				hideSpoj: boolean,
				hideCodeChef: boolean
			},
			atPayment: boolean
		}
	) {
		Object.assign(this.hidePlatforms, data.hidePlatforms);
		this.invokedAtPayment = data.atPayment ? data.atPayment : false;
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	ngOnInit(): void {

	}
}
