import { Injectable } from '@angular/core';

@Injectable()
export class LogoService {
	private logos: Array<string>;

	constructor() {
		this.logos = [];
		for (let i = 0; i < 5; i++) {
			this.logos.push('' + i);
		}
	}
	public getLogos(): Array<string> {
		return this.logos;
	}
}
