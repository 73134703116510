<mat-toolbar color="primary">Reasons for Unsubscription</mat-toolbar>
<div class="unsub-container">
	<mat-card class="mat-elevation-z0">
		<form [formGroup]="unsubscriptionForm.controls['checkboxes']">
			<div *ngFor="let item of reasons; let i=index;" fxLayout="column" fxLayoutAlign="space-between stretch">
				<mat-checkbox color="primary" (change)="onCheckReason($event, item)" (formControlName)="item.reason">
					{{item.reason}}</mat-checkbox>
			</div>
		</form>
		<mat-card-actions>
			<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="40px">
				<button mat-raised-button fxFlex="0 0 auto" (click)="onDismiss()">Cancel</button>
				<button *ngIf="unsubscriptionForm.valid" mat-raised-button fxFlex="0 0 auto"
					(click)="submitUnsubscriptionForm()" [disabled]="unsubscribeReasonsIds.length == 0" mat-button
					cdkFocusInitial>Submit</button>
			</div>
		</mat-card-actions>
	</mat-card>
</div>