/* eslint-disable no-unused-vars */
export enum BatchTypes {
	WEEKEND_CODING = 'WeekendCoding',
	COLLEGE_CODING = 'CollegeCoding',
	WEEKDAY_CODING = 'WeekdayCoding',
	SMART_DESIGN = 'SmartDesign',
	SMART_ML = 'SmartML'
}

export enum AssignmentType {
	READING = 'Reading Assignment',
	CODING = 'Coding Assignment'
};

export enum BatchRoles {
	STUDENT = 'STUDENT',
	MENTOR = 'MENTOR',
	ADMIN = 'ADMIN',
	INSTRUCTOR = 'INSTRUCTOR',
	SUPERADMIN = 'SUPERADMIN'
}

export enum QuizStatus {
	PAST = 'Past',
	LIVE = 'Live',
	UPCOMING = 'Upcoming'
}

export enum QuizQuestionDifficulty {
	EASY = 'Easy',
	MEDIUM = 'Medium',
	HARD = 'Hard'
};

export enum QuizQuestionType {
	SINGLE = 'Single',
	MULTIPLE = 'Multiple',
	TEXT = 'Text'
};

export enum BatchType {
	WEEKEND_CODING = 'WeekendCoding',
	COLLEGE_CODING = 'CollegeCoding',
	WEEKDAY_CODING = 'WeekdayCoding',
	SMART_DESIGN = 'SmartDesign',
	SMART_ML = 'SmartML'
};

export enum MatSortHeaderNames {
	RANK = 'rank',
	NAME = 'name',
	BRANCH = 'branch',
	USERNAME = 'username',
	EMAIL = 'email',
	MOBILE = 'mobile',
	INSTITUTION = 'institution',
	ENDYEAR = 'endYear',
	ROLLNUMBER = 'rollNumber',
	OVERALL = 'overallSub',
	HR_DS = 'hrDsSub',
	HR_USERNAME = 'hrUsernameSub',
	HR_ALGO = 'hrAlgoSub',
	HR_TOTAL = 'hrTotal',
	CODECHEF_USERNAME = 'ccUsernameSub',
	CODECHEF_RATING = 'ccRatingSub',
	CODECHEF_NUMBEROFCONTESTS = 'ccNumberOfContestsSub',
	CODECHEF_FULLYSOLVED = 'ccFullySolvedSub',
	CODECHEF_TOTAL = 'ccTotal',
	LEETCODE_USERNAME = 'lcUsernameSub',
	LEETCODE_RATING = 'lcRatingSub',
	LEETCODE_NUMBEROFCONTESTS = 'lcNumberOfContestsSub',
	LEETCODE_FULLYSOLVED = 'lcFullySolvedSub',
	LEETCODE_TOTAL = 'lcTotal',
	CODEFORCES_USERNAME = 'cfUsernameSub',
	CODEFORCES_RATING = 'cfRatingSub',
	CODEFORCES_NUMBEROFCONTESTS = 'cfNumberOfContestsSub',
	CODEFORCES_FULLYSOLVED = 'cfSolvedSub',
	CODEFORCES_TOTAL = 'cfTotal',
	SPOJ_USERNAME = 'spojUsernameSub',
	SPOJ_POINTS = 'spojPointsSub',
	SPOJ_FULLYSOLVED = 'spojSolvedSub',
	SPOJ_TOTAL = 'spojTotal',
	INTERVIEWBIT_USERNAME = 'ibUsernameSub',
	INTERVIEWBIT_SCORE = 'interviewbitScore',
	INTERVIEWBIT_TOTAL = 'interviewbitTotal',
	SI_SCORE = 'siScore',
	SIBASIC_SCORE = 'siBasicScore',
	CONTESTS_SUB1 = 'contest-1',
	CONTESTS_SUB2 = 'contest-2',
	CONTESTS_SUB3 = 'contest-3',
	CONTESTS_SUB4 = 'contest-4',
	CONTESTS_SUB5 = 'contest-5',
	EXPERIENCE = 'experience',
	LASTLOGINTIME = 'lastLoginTime'
}

export enum ChipColors {
	INSTRUCTOR_BACKGROUND_COLOR = '#3F51B5',
	MENTOR_BACKGROUND_COLOR = '#FFC107',
	STUDENT_BACKGROUND_COLOR = '#C6C6C6'
};

export enum BatchState {
	ACTIVE = 'Active',
	COMPLETED = 'Completed',
	UPCOMING = 'Upcoming',
	CANCELLED = 'Cancelled'
};

export enum PlatformImages {
	CodeChef = 'https://imagedelivery.net/jP_2Cu8opO0otIERyhqaNQ/7ce47a28-2f5c-4f1e-3bfc-6fc1e46aca00/public',
	Codeforces = 'https://imagedelivery.net/jP_2Cu8opO0otIERyhqaNQ/f2763423-2e7a-4729-7250-c844608b3900/public',
	HackerRank = 'https://imagedelivery.net/jP_2Cu8opO0otIERyhqaNQ/99b649bb-89a2-4256-8f96-211f99b3ae00/public',
	InterviewBit = 'https://imagedelivery.net/jP_2Cu8opO0otIERyhqaNQ/60faaa5c-cc7c-4477-5b30-c262eb3f0100/public',
	LeetCode = 'https://imagedelivery.net/jP_2Cu8opO0otIERyhqaNQ/b9085b94-c4a2-43ba-3662-3d60f024e500/public',
	Spoj = 'https://imagedelivery.net/jP_2Cu8opO0otIERyhqaNQ/498e4079-3be6-460f-c084-229f5e523700/public',
	Github = 'https://imagedelivery.net/jP_2Cu8opO0otIERyhqaNQ/453c3870-91e6-4521-259a-c81d9db27600/public'
};

export enum RequestType {
	TRANSFER_REQUEST = 'TransferRequest',
	REFUND_REQUEST = 'RefundRequest'
};

export enum BatchRole {
	STUDENT = 'STUDENT',
	MENTOR = 'MENTOR',
	ADMIN = 'ADMIN',
	INSTRUCTOR = 'INSTRUCTOR'
};
