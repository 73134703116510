import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { UserService } from '../../shared';
import { MatPaginator } from '@angular/material/paginator';
import { take, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ScoresDataSource } from '../ScoresDataSource';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { BatchRoles, ChipColors } from '../../shared/enums';

@Component({
	selector: 'app-leaderboard',
	templateUrl: './leaderboard.component.html',
	styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(
		private userService: UserService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title
	) {
		this.updateTitle();
		this.dataSource = new ScoresDataSource(this.userService, this.router, true);
	}
	/** The order in which the columns are to be displayed, given their ids */
	displayedColumns: string[] = ['rank', 'username', 'overall'];
	/** The data source for the leaderboard */
	dataSource: ScoresDataSource;
	pageSizeOptions: number[] = [10, 25, 50, 100];
	pageSize: number = this.pageSizeOptions[2];
	batchName: string;
	batchType: string;
	registeredUser = false;
	isLoggedIn = false;

	activeBatches = [];
	nonActiveBatches = [];
	/** Role field for coloring the batch tags on leaderboard based on role */
	role = {};
	/** obj stores all observables and unsubscribes them onDestroy */
	private obj: Subscription = new Subscription();
	private oldBatchName: string;
	private oldPageSize: number;
	private oldPageIndex: number;

	ngOnInit() {
		this.obj.add(
			this.dataSource.batches$.subscribe((batches) => {
				this.activeBatches = batches.active;
				this.nonActiveBatches = batches.nonactive;
				this.role = batches.role;
				if (batches.batchType == 'CollegeCoding') {
					this.batchType = 'CollegeCoding';
					this.displayedColumns = ['rank', 'rollNumber', 'username', 'overall'];
				} else {
					this.batchType = 'WeekendCoding';
					this.displayedColumns = ['rank', 'username', 'overall'];
				}
				this.userService.isAuthenticated.pipe(take(1)).subscribe((isAuthed) => {
					if (batches && ((batches.active && batches.active.length !== 0) ||
						(batches.nonactive && batches.nonactive.length !== 0))) {
						this.registeredUser = true;
					} else {
						this.registeredUser = false;
					}

					this.isLoggedIn = isAuthed;
				});
			})
		);
	}

	ngAfterViewInit() {
		this.updateTitle();
		/** Watch for route change to update the paginator */
		this.obj.add(this.route.url
			.subscribe((url) => {
				this.updateTitle();
				let pageIndex = this.route.snapshot.params['pageIndex'] || 1;
				pageIndex = parseInt(pageIndex, 10) - 1;
				/**
	 * When a user navigates using browser back button
	 * the paginator event will not be fired, so trigger that event
	 */
				this.updatePaginator(pageIndex, this.paginator.pageSize);
				/**
	 * The change in batchName will not trigger an update in updatePaginator but the data
	 * must be updated
	 */
				this.updateData(pageIndex, this.paginator.pageSize, this.batchName);
			}));

		/** Binding paginator information with the data source */
		this.obj.add(this.paginator.page.pipe(
			/** Avoid multiple concurrent updates */
			debounceTime(500),
			distinctUntilChanged()
		).subscribe(() => {
			this.router.navigate(['../', this.paginator.pageIndex + 1], { relativeTo: this.route });
			/**
	 * Route update may not always be triggered. For ex: when page size changed.
	 * So, trigger the data update again.
	 */
			this.updateData(this.paginator.pageIndex, this.paginator.pageSize, this.batchName);
		}));
		document.getElementsByClassName('mat-paginator-page-size-label')[0].innerHTML = 'User per page';
	}

	ngOnDestroy(): void {
		this.titleService.setTitle('Smart Interviews');
		this.obj.unsubscribe();
	}

	/** Update the data only if we find that some value has changed */
	private updateData(pageIndex: number, pageSize: number, batchName: string) {
		if (pageIndex !== this.oldPageIndex ||
			pageSize !== this.oldPageSize ||
			batchName !== this.oldBatchName) {
			this.oldBatchName = batchName;
			this.oldPageSize = pageSize;
			this.oldPageIndex = pageIndex;
			this.dataSource.loadScores(this, pageIndex, pageSize, batchName);
		}
	}

	private updateTitle(): void {
		if (this.route.snapshot.params['batchName']) {
			this.batchName = this.route.snapshot.params['batchName'];
			this.titleService.setTitle(`${this.batchName} | Leaderboard | Smart Interviews`);
		} else {
			this.batchName = '';
			this.titleService.setTitle('Leaderboard | Smart Interviews');
		}
	}

	/**
	 * Reset the leaderboard to global leaderboard
	 */
	resetData(): void {
		this.router.navigate(['/leaderboard']);
	}

	/**
	 * Changes the route to relevant batch selected in the filter
	 * @param batchName the name of the batch for the leaderboard
	 */
	filterData(batchName: string): void {
		this.router.navigate(['/leaderboard/batch', batchName]);
	}

	/**
	 * Updates the custom paginator and emits a PageEvent to fetch the
	 * required data
	 * @param index Index of the page required
	 * @param pageSize Size of the page required
	 */
	updatePaginator(index: number = 0, pageSize: number = this.pageSize): void {
		const prevIndex = this.paginator.pageIndex;
		this.pageSize = pageSize;
		this.paginator.pageIndex = index;
		this.paginator.pageSize = pageSize;
		/** Emit value identical to the paginator event */
		this.paginator.page.emit({
			previousPageIndex: prevIndex,
			pageIndex: index,
			pageSize: this.paginator.pageSize,
			length: this.paginator.length
		});
	}

	getBackgroundColor(role) {
		if (role === BatchRoles.ADMIN || role === BatchRoles.INSTRUCTOR) {
			return ChipColors.INSTRUCTOR_BACKGROUND_COLOR;
		} else if (role === BatchRoles.MENTOR) {
			return ChipColors.MENTOR_BACKGROUND_COLOR;
		}
		return ChipColors.STUDENT_BACKGROUND_COLOR;
	}
}
