import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from '../models';
import { UserService, HelpersService } from '../services';
import { MatDialog } from '@angular/material/dialog';
import { NotifyUpcomingBatchDialogComponent } from '../../courses/notify-upcoming-batch-dialog/notify-upcoming-batch-dialog.component';
import { take } from 'rxjs/operators';

@Component({
	selector: 'layout-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
	currentUser: User;
	displayName: String;
	showLoader: Boolean;
	isRegistered: Boolean = false;
	upcomingCodingBatchData = {
		received: false,
		batchName: '',
		startDate: new Date,
		isEnrollable: true
	};
	public showAdministrationMenu = false;
	subscription = new Subscription();
	showButton: boolean = false;

	profilePictureURL: String;
	defaultPath = 'https://imagedelivery.net/jP_2Cu8opO0otIERyhqaNQ/71854130-0d5f-4028-37ce-35dfd6007500/public';

	constructor(
		private userService: UserService,
		public helperService: HelpersService,
		private router: Router,
		private dialog: MatDialog
	) {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				/** Register now button on header is not showon on /register, /login, /verifyotp, /register/batch/..  */
				if (/\/register/.test(event.url) ||
					/\/login/.test(event.url) ||
					/\/verifyotp/.test(event.url) ||
					/\/forgot-password/.test(event.url)
				) {
					this.showButton = false;
				} else {
					this.showButton = true;
				}
			}
		});
	}

	@HostListener('window:resize', ['$event'])

	ngOnInit() {
		this.upcomingSmartCoderBatch();
		this.subscription.add(this.userService.currentUser.subscribe(
			(userData) => {
				this.currentUser = userData;
				this.displayName = userData.usernameCaseSensitive;
				this.isRegistered = (typeof userData.isRegistered === 'boolean') ? userData.isRegistered : true;
				if (userData.roleLevel >= 2) {
					this.showButton = false;
				}
			}
		));

		this.subscription.add(this.helperService.loader$.subscribe(
			(isLoadedResp) => {
				this.showLoader = isLoadedResp;
			}
		));

		this.subscription.add(
			this.userService.isAuthenticated
				.subscribe((resp) => {
					if (resp) {
						this.subscription.add(this.userService.getImageUrl().subscribe((resp) => {
							const path = resp.data.path;
							this.profilePictureURL = path === null ? this.defaultPath : path;
						}, (err) => {
							this.profilePictureURL = this.defaultPath;
						}));
					} else {
						// supress
					}
				})
		);


		this.subscription.add(this.userService.roleOfTheUser
			.subscribe((level) => {
				if (level > 1) {
					this.showAdministrationMenu = true;
				} else {
					this.showAdministrationMenu = false;
				}
			}));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	removeUser() {
		this.userService.purgeAuth();
		this.router.navigate(['/login']);
		return false;
	}

	upcomingSmartCoderBatch() {
		this.userService.upcomingSmartCoderBatch().subscribe((res) => {
			this.upcomingCodingBatchData = { ...res.data, received: true };
		}, (err) => {
			this.upcomingCodingBatchData = undefined;
		});
	}

	registerHandler() {
		if (this.upcomingCodingBatchData.isEnrollable) {
			this.router.navigateByUrl(`/register/batch/${this.upcomingCodingBatchData.batchName}`);
		} else if (this.upcomingCodingBatchData.batchName) {
			const dialogRef = this.dialog.open(NotifyUpcomingBatchDialogComponent, {
				width: '400px',
				data: {
					batchName: this.upcomingCodingBatchData.batchName
				}
			});
			dialogRef.afterClosed()
				.pipe(take(1))
				.subscribe((result) => {
					if (result) {
						// success
					}
				});
		}
	}
}
