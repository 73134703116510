<!--
    This is a component with [<image> <username>]

    Use cases:
    - Leaderboard
    - Batch Report
    - Navbar

    [link] -> is used for enabling or disabling the username text as a link to their profile

    Navbar usecase will not have a link but has a different view for mobile
-->

<img *ngIf="publicView && dpImageFile" class="profile" [src]="resizeImage || defaultPath" alt="" />
<img *ngIf="!publicView || !dpImageFile" class="profile" [src]="defaultPath" alt="" />


<span *ngIf="link">
    <a *ngIf="publicView" [routerLink]="['/profile', username]">
        {{username}}
    </a>
    <span *ngIf="!publicView">
        {{username}}
    </span>
</span>

<!-- Navbar usecase -->
<span *ngIf="!link" class="name">
    <span>
        {{username}}
    </span>
</span>
