import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';

@Injectable()
export class OtpService {
	constructor(
        private apiService: ApiService,
        private jwtService: JwtService
	) { }


	resendOtp(rurl) {
		const data = {};
		data['username'] = this.jwtService.getUsername();
		data['hashedValue'] = this.jwtService.getHash(); // comes in change email/phone
		data['value'] = this.jwtService.userTemp; //  comes in sign up flow or forgot password flow
		data['type'] = this.jwtService.type;
		return this.apiService.post(`${environment.otp_url}${rurl}`, data);
	}

	sendPassword(rurl, data): Observable<any> {
		const sendUrl = '/sendPassword';
		data.value = this.jwtService.userTemp;
		data.type = this.jwtService.type;
		// this.jwtService.saveEmailOrMobile(data.emailorphone);
		return this.apiService.post(`${environment.otp_url}${sendUrl}`, data);
	}

	storeAndSendOtp(data) {
		const url = '/storeAndGenerateHash';
		data['username'] = this.jwtService.getUsername();
		return this.apiService.post(`${environment.user_url}${url}`, data).pipe(map((res) => {
			this.jwtService.userTemp = data.value;
			this.jwtService.type = data.type;
			this.jwtService.saveHash(res.data.hashedValue);
			return res;
		}));
	}

	sendOtpTo(data) {
		const route = '/sendOtpTo';
		return this.apiService.post(`${environment.otp_url}${route}`, data);
	}

	sendOtp(data) {
		const sendUrl = '/sendOtp';
		return this.apiService.post(`${environment.otp_url}${sendUrl}`, data);
	}

	verifyOtp(rurl, data) {
		const verifyUrl = '/VerifyOtp';
		data['username'] = this.jwtService.getUsername();
		return this.apiService.post(`${environment.otp_url}${verifyUrl}`, data);
	}

	sendRegisterMessage(data) {
		const route = '/registerMessage';
		data['username'] = this.jwtService.getUsername();
		return this.apiService.post(`${environment.otp_url}${route}`, data);
	}
}
