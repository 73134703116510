import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService, HelpersService } from './shared';
import { JwtService } from './shared/services/jwt.service';
import {
	Event,
	NavigationStart,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	ActivatedRoute,
	Router
} from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
	private subscription = new Subscription();
	constructor(
		private userService: UserService,
		private jwtService: JwtService,
		private helpers: HelpersService,
		private _router: Router,
		private route: ActivatedRoute
	) {
		this.subscription = this._router.events.subscribe((event: Event) => {
			if (this._router.url.includes('/report') || this._router.url.includes('/dashboard') || this._router.url.includes('/admin')) {
				this.displayHeader = false;
			} else {
				this.displayHeader = true;
			}
			switch (true) {
				case event instanceof NavigationStart: {
					if (this.helpers.isLoading) {
						return;
					}
					this.helpers.showLoader();
					break;
				}

				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					this.helpers.hideLoader();
					break;
				}
				default: {
					break;
				}
			}
		});
	}
	title = 'Smart Interviews';
	displayHeader = true;
	ngOnInit() {
		this.userService.populate();
		this.checkReferralQueryParams();
	}
	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	/** checks if queryparams contains refId or rc and makes a backend call  */
	checkReferralQueryParams() {
		this.route.queryParams
			.subscribe((queryParams: any) => {
				if (queryParams.rc || queryParams.refId) {
					/** store rc in localstorage */
					this.jwtService.setRC(queryParams.rc);

					this.userService.sendReferralQueryParams(queryParams.refId, queryParams.rc)
						.subscribe(
							(data) => {
							}, (err) => {
							}
						);
				}
			});
	}
}
