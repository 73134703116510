import { Injectable } from '@angular/core';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
	providedIn: 'root'
})
export class ConfirmDialogService {
	constructor(
        public dialog: MatDialog) { }

	public confirm(
		title: string,
		message: string,
		btnOkText: string = 'Confirm',
		btnCancelText: string = 'Cancel'): Observable<boolean> {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '300px',
			data: { title: title, message: message, btnOkText: btnOkText, btnCancelText: btnCancelText }
		});

		return dialogRef.afterClosed();
	}
}
