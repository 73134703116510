import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable()
export class HelpersService {
	private loaderSubject = new ReplaySubject<boolean>(1);
	public loader$ = this.loaderSubject.asObservable().pipe(distinctUntilChanged());
	public isLoading: boolean;
	public isTouchDevice: boolean;

	constructor() {
		this.loader$.subscribe((resp) => this.isLoading = resp);

		// check if device is a touch device
		if ('ontouchstart' in document.documentElement) {
			this.isTouchDevice = true;
		} else {
			this.isTouchDevice = false;
		}
	}

	/**
     * @description Prevents non-digit characters in input
     * @param event keydown
     */
	public onlyNumbers(event) {
		// 'up': 38,'right':39,'down':40,'left':37,
		// 'escape':27,'backspace':8,'tab':9,'enter':13,'del':46
		const keys = { 38: true, 39: true, 40: true, 37: true,
			27: true, 8: true, 9: true, 13: true, 46: true, 90: true };
		const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j',
			'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
		// [48-57] for regular numbers. [96-105] for numpad numbers.
		const number = (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105);
		const combination = event.ctrlKey && alphabet.includes(event.key);

		if (combination) return;

		// if the pressed key is not listed and not a number, do nothing
		if (!keys[event.keyCode] && !number) {
			event.preventDefault();
		}
	}

	/** Sets focus on the first input text box of the page */
	public autofocus() {
		document.getElementById('autofocus').focus();
	}

	/** Displays full page loader */
	public showLoader() {
		this.loaderSubject.next(true);
	}

	/** Removes full page loader */
	public hideLoader() {
		this.loaderSubject.next(false);
	}

	public validateAllFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach((field) => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsDirty({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFields(control);
			}
		});
	}

	public detectMobile = () => window.innerWidth <= 768;
}
