<div class="contact-container">
	<div class="title">
		<h1>Contact Us</h1>
		<div class="divider"></div>
	</div>
	<div class="row">
		<div class="col-md-4 contact-info">
			<div class="social-contact row">
				<div class="col-xs-2">
					<a href="https://www.facebook.com/SmartInterviews" target="_blank">
						<i class="mdi mdi-facebook-box"></i>
					</a>
				</div>
				<div class="col-xs-2">
					<a href="https://twitter.com/smart_interview" target="_blank">
						<i class="mdi mdi-twitter-box"></i>
					</a>
				</div>
				<div class="col-xs-2">
					<a href="https://www.linkedin.com/company/smart-interviews/" target="_blank">
						<i class="mdi mdi-linkedin-box"></i>
					</a>
				</div>
				<div class="col-xs-2">
					<a href="https://www.youtube.com/channel/UC3JtcFSnkIgi_l62SwnAcpA" target="_blank">
						<i class="mdi mdi-youtube-play"></i>
					</a>
				</div>
				<div class="col-xs-2">
					<a href="https://instagram.com/smartinterviews" target="_blank">
						<i class="mdi mdi-instagram"></i>
					</a>
				</div>
				<div class="col-xs-2 ">
					<a href="https://www.hackerrank.com/smart-interviews" target="_blank">
						<img alt="HR" class="hackerrank"
							src="https://imagedelivery.net/jP_2Cu8opO0otIERyhqaNQ/c2be8395-3d30-4683-3197-9b2efe777800/public">
					</a>
				</div>
				<div class="col-xs-2"></div>
			</div>
			<div class="mobile-contact row">
				<i class="col-xs-2 mdi mdi-cellphone-iphone"></i>
				<p class="col-xs-10">
					<a href="tel:+917272937937">+91-7272-937-937</a>
				</p>
			</div>
			<div class="email-contact row">
				<i class=" col-xs-2 mdi mdi-email-variant"></i>
				<p class="col-xs-10">
					<a href="mailto:contact@smartinterviews.in">
						contact@smartinterviews.in
					</a>
				</p>
			</div>
		</div>
		<div class="col-md-4 discord-wrapper">
			<iframe src="https://discord.com/widget?id=842630216210972672&theme=light" width="90%" height="300"
				allowtransparency="true" frameborder="0"
				sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
			</iframe>
		</div>
	</div>
</div>
<app-contact-form></app-contact-form>
<layout-footer></layout-footer>

<script>
	(function (d, s, id) {
		var js, fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		js = d.createElement(s);
		js.id = id;
		js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.8&appId=157603360945164";
		fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'facebook-jssdk'));

</script>