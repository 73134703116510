import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable()
export class XlsxService {
	toXlsxAndWrite(data, filename, sheetname = 'Sheet') {
		/** Create new WorkSheet */
		const ws = XLSX.utils.json_to_sheet(data);

		/** Create new WorkBook */
		const wb = XLSX.utils.book_new();

		/** Append WorkSheet to WorkBook */
		XLSX.utils.book_append_sheet(wb, ws, sheetname);

		XLSX.writeFile(wb, filename);
	}
}
