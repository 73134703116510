import { UserService } from '../shared';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, tap, take } from 'rxjs/operators';
import { Score } from '../shared/models/score.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { MatSortHeaderNames } from './../shared/enums';

export class ScoresDataSource implements DataSource<Score> {
	private scoresSubject = new BehaviorSubject<Score[]>([]);
	private filtersSubject = new BehaviorSubject<any>({});
	private countSubject = new BehaviorSubject<number>(10);
	private totalCountSubject = new BehaviorSubject<number>(10);
	private batchesSubject = new BehaviorSubject<any>({});
	private loadingSubject = new BehaviorSubject<boolean>(true);
	private showRegisterMsg = new BehaviorSubject<boolean>(false);
	private subscriptions: Subscription = new Subscription();

	private lastSort: { sort: Sort, isDateRangeSelected: boolean } = undefined;

	private initialAllScores: any[] = [];
	private filters: any = {};
	matSortHeaderNames = MatSortHeaderNames;
	public isDataSorted = false;
	public count$ = this.countSubject.asObservable();
	public totalCount$ = this.totalCountSubject.asObservable();
	public showRegisterMsg$ = this.showRegisterMsg.asObservable();
	public batches$ = this.batchesSubject.asObservable();
	public scores$ = this.scoresSubject.asObservable();
	public filters$ = this.filtersSubject.asObservable();
	public loading$ = this.loadingSubject.asObservable();

	public isAdmin = false;
	public batchType = '';
	public UIReportHiddenColumns: [string];
	public internalContests = [];
	public targets = [];
	public groupLink: string;
	public batchId = '';
	public colors = [];
	public batchStartDate = null;
	public attendancesLen: number;
	public batchState: string;
	public enableConfigureAttendance = false;
	public enableManualAttendance = false;
	public enableConfigureTarget = false;
	public enableViewAttendance = false;
	public enableBatchActivity = false;
	public enableSendOrDownloadAttendanceReport = false;
	public enableScoreUpdate = false;
	public enableHeaderPercentages = false;
	public totalStudents = 0;
	public phaseOne = false;
	public showLinkedInRegister = false;
	public showEducationDialog = false;
	public showCodingProfilesDialog = false;
	public enableCheckMarkActivity = false;
	public discordToolTip: string;
	public isChildBatch = false;

	constructor(private userService: UserService,
		private router: Router,
		private isBatch: boolean
	) { }

	connect(_collectionViewer: CollectionViewer): Observable<Score[]> {
		return this.scoresSubject.asObservable();
	}

	disconnect(_collectionViewer: CollectionViewer): void {
		this.subscriptions.unsubscribe();
		this.scoresSubject.complete();
		this.countSubject.complete();
		this.totalCountSubject.complete();
		this.showRegisterMsg.complete();
	}

	/**
	 * Fetches the scores for the given pagination requirements and updates `scoresSubject`
	 * and `countSubject`
	 * @param pageIndex The index of the page i.e. page number (0 based)
	 * @param pageSize The number of entries displayed per page
	 */
	loadScores(leaderboardComponent: any = null, pageIndex: number = 0, pageSize: number = 10, batchName?: string) {
		this.loadingSubject.next(true);
		this.userService
			.fetchLeaderboard(pageIndex, pageSize, batchName)
			.pipe(
				tap(
					(val) => { },
					(err) => {
						this.router.navigate(['/404'], {
							skipLocationChange: true
						});
					}
				),
				catchError(() => of([])),
				finalize(() => this.loadingSubject.next(false)),
				take(1)
			)
			.subscribe((result) => {
				const finalPageIndex: number = ((result.data.count % pageSize) === 0) ?
					Math.floor(result.data.count / pageSize) :
					Math.floor(result.data.count / pageSize) + 1;
				this.batchesSubject.next(result.data.batches);
				this.countSubject.next(result.data.count);
				this.totalCountSubject.next(result.data.totalCount);
				this.scoresSubject.next(result.data.allScores);
				if (this.isBatch && finalPageIndex > 1 && result.data.totalCount < (pageSize * (pageIndex + 1))) {
					leaderboardComponent.router.navigate(['../', finalPageIndex],
						{ relativeTo: leaderboardComponent.route });
				}
			});
	}

	loadReport(batchName: string, startDate?: Date, endDate?: Date) {
		this.loadingSubject.next(true);
		this.userService
			.fetchBatchReport(batchName, startDate, endDate)
			.pipe(
				tap(
					(val) => { },
					(err) => {
						this.router.navigate(['/404'], {
							skipLocationChange: true
						});
					}
				),
				catchError(() => of([])),
				finalize(() => this.loadingSubject.next(false)),
				take(1)
			)
			.subscribe((result) => {
				if (result.status) {
					this.isDataSorted = false;
					this.isAdmin = result.data.isAdmin;
					this.batchType = result.data.batchType;
					this.UIReportHiddenColumns =
						result.data.UIReportHiddenColumns;
					this.internalContests = result.data.internalContests;
					this.targets = result.data.targets;
					this.groupLink = result.data.groupLink;
					this.discordToolTip = result.data.discordToolTip;
					this.batchId = result.data.batchId;
					this.batchState = result.data.batchState;
					this.colors = result.data.colors;
					this.batchStartDate = result.data.startDate;
					this.attendancesLen = result.data.attendancesLen;
					this.enableConfigureAttendance = result.data.enableConfigureAttendance;
					this.enableManualAttendance = result.data.enableManualAttendance;
					this.enableConfigureTarget = result.data.enableConfigureTarget;
					this.enableViewAttendance = result.data.enableViewAttendance;
					this.enableBatchActivity = result.data.enableBatchActivity;
					this.enableSendOrDownloadAttendanceReport =
						result.data.enableSendOrDownloadAttendanceReport;
					this.enableScoreUpdate = result.data.enableScoreUpdate;
					this.enableHeaderPercentages =
						result.data.enableHeaderPercentages;
					this.phaseOne = result.data.phaseOne;
					this.totalStudents = result.data.totalStudents;
					this.initialAllScores = result.data.allScores;
					this.showLinkedInRegister = result.data.showLinkedInRegister;
					this.showEducationDialog = result.data.showEducationDialog;
					this.showCodingProfilesDialog = result.data.showCodingProfilesDialog;
					this.enableCheckMarkActivity = result.data.enableCheckMarkActivity;
					this.isChildBatch = result.data.isChildBatch;
					this.scoresSubject.next(result.data.allScores);
				} else {
					this.router.navigate(['/404'], {
						skipLocationChange: true
					});
				}
			});
	}

	onSortData(sort: Sort, isDateRangeSelected: boolean) {
		this.lastSort = { sort, isDateRangeSelected };
		this.isDataSorted = true;
		const data = this.scoresSubject.getValue();
		if (sort.active && sort.direction !== '') {
			const isAsc = sort.direction === 'asc';

			if (sort.active === this.matSortHeaderNames.HR_DS) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.hackerRank.difference.ds :
							a.hackerRank.latestScore.ds,
						isDateRangeSelected ?
							b.hackerRank.difference.ds :
							b.hackerRank.latestScore.ds,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.HR_USERNAME) {
				data.sort((a: any, b: any) =>
					this.compareStrings(a.hrUsername || '', b.hrUsername || '', isAsc)
				);
			} else if (sort.active === this.matSortHeaderNames.HR_ALGO) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.hackerRank.difference.algo :
							a.hackerRank.latestScore.algo,
						isDateRangeSelected ?
							b.hackerRank.difference.algo :
							b.hackerRank.latestScore.algo,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.HR_TOTAL) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.hackerRank.difference.total :
							a.total.hr,
						isDateRangeSelected ?
							b.hackerRank.difference.total :
							b.total.hr,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.CODECHEF_USERNAME) {
				data.sort((a: any, b: any) =>
					this.compareStrings(a.ccUsername || '', b.ccUsername || '', isAsc)
				);
			} else if (
				sort.active === this.matSortHeaderNames.CODECHEF_RATING
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.codeChef.difference.rating :
							a.codeChef.latestScore.rating,
						isDateRangeSelected ?
							b.codeChef.difference.rating :
							b.codeChef.latestScore.rating,
						isAsc
					)
				);
			} else if (
				sort.active ===
				this.matSortHeaderNames.CODECHEF_NUMBEROFCONTESTS
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.codeChef.difference.numberOfContests :
							a.codeChef.latestScore.numberOfContests,
						isDateRangeSelected ?
							b.codeChef.difference.numberOfContests :
							b.codeChef.latestScore.numberOfContests,
						isAsc
					)
				);
			} else if (
				sort.active === this.matSortHeaderNames.CODECHEF_FULLYSOLVED
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.codeChef.difference.fullySolved :
							a.codeChef.latestScore.fullySolved,
						isDateRangeSelected ?
							b.codeChef.difference.fullySolved :
							b.codeChef.latestScore.fullySolved,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.CODECHEF_TOTAL) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.codeChef.difference.total :
							a.total.cc,
						isDateRangeSelected ?
							b.codeChef.difference.total :
							b.total.cc,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.LEETCODE_USERNAME) {
				data.sort((a: any, b: any) =>
					this.compareStrings(a.lcUsername || '', b.lcUsername || '', isAsc)
				);
			} else if (
				sort.active === this.matSortHeaderNames.LEETCODE_RATING
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.leetcode.difference.rating :
							a.leetcode.latestScore.rating,
						isDateRangeSelected ?
							b.leetcode.difference.rating :
							b.leetcode.latestScore.rating,
						isAsc
					)
				);
			} else if (
				sort.active ===
				this.matSortHeaderNames.LEETCODE_NUMBEROFCONTESTS
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.leetcode.difference.numberOfContests :
							a.leetcode.latestScore.numberOfContests,
						isDateRangeSelected ?
							b.leetcode.difference.numberOfContests :
							b.leetcode.latestScore.numberOfContests,
						isAsc
					)
				);
			} else if (
				sort.active === this.matSortHeaderNames.LEETCODE_FULLYSOLVED
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.leetcode.difference.fullySolved :
							a.leetcode.latestScore.fullySolved,
						isDateRangeSelected ?
							b.leetcode.difference.fullySolved :
							b.leetcode.latestScore.fullySolved,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.LEETCODE_TOTAL) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.leetcode.difference.total :
							a.total.lc,
						isDateRangeSelected ?
							b.leetcode.difference.total :
							b.total.lc,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.CODEFORCES_USERNAME) {
				data.sort((a: any, b: any) =>
					this.compareStrings(a.cfUsername || '', b.cfUsername || '', isAsc)
				);
			} else if (
				sort.active === this.matSortHeaderNames.CODEFORCES_RATING
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.codeForces.difference.rating :
							a.codeForces.latestScore.rating,
						isDateRangeSelected ?
							b.codeForces.difference.rating :
							b.codeForces.latestScore.rating,
						isAsc
					)
				);
			} else if (
				sort.active ===
				this.matSortHeaderNames.CODEFORCES_NUMBEROFCONTESTS
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.codeForces.difference.numberOfContests :
							a.codeForces.latestScore.numberOfContests,
						isDateRangeSelected ?
							b.codeForces.difference.numberOfContests :
							b.codeForces.latestScore.numberOfContests,
						isAsc
					)
				);
			} else if (
				sort.active === this.matSortHeaderNames.CODEFORCES_FULLYSOLVED
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.codeForces.difference.submissionsOk :
							a.codeForces.latestScore.submissionsOk,
						isDateRangeSelected ?
							b.codeForces.difference.submissionsOk :
							b.codeForces.latestScore.submissionsOk,
						isAsc
					)
				);
			} else if (
				sort.active === this.matSortHeaderNames.CODEFORCES_TOTAL
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.codeForces.difference.total :
							a.total.cf,
						isDateRangeSelected ?
							b.codeForces.difference.total :
							b.total.cf,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.SPOJ_USERNAME) {
				data.sort((a: any, b: any) =>
					this.compareStrings(a.spojUsername || '', b.spojUsername || '', isAsc)
				);
			} else if (sort.active === this.matSortHeaderNames.SPOJ_POINTS) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.spoj.difference.points :
							a.spoj.latestScore.points,
						isDateRangeSelected ?
							b.spoj.difference.points :
							b.spoj.latestScore.points,
						isAsc
					)
				);
			} else if (
				sort.active === this.matSortHeaderNames.SPOJ_FULLYSOLVED
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.spoj.difference.problemsSolved :
							a.spoj.latestScore.problemsSolved,
						isDateRangeSelected ?
							b.spoj.difference.problemsSolved :
							b.spoj.latestScore.problemsSolved,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.SPOJ_TOTAL) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.spoj.difference.total :
							a.total.spoj,
						isDateRangeSelected ?
							b.spoj.difference.total :
							b.total.spoj,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.INTERVIEWBIT_USERNAME) {
				data.sort((a: any, b: any) =>
					this.compareStrings(a.ibUsername || '', b.ibUsername || '', isAsc)
				);
			} else if (
				sort.active === this.matSortHeaderNames.INTERVIEWBIT_SCORE
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.interviewBit.difference.score :
							a.interviewBit.latestScore.score,
						isDateRangeSelected ?
							b.interviewBit.difference.score :
							b.interviewBit.latestScore.score,
						isAsc
					)
				);
			} else if (
				sort.active === this.matSortHeaderNames.INTERVIEWBIT_TOTAL
			) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.interviewBit.difference.total :
							a.total.ib,
						isDateRangeSelected ?
							b.interviewBit.difference.total :
							b.total.ib,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.SI_SCORE) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.SI.difference.siScore :
							a.SI.latestScore.siScore,
						isDateRangeSelected ?
							b.SI.difference.siScore :
							b.SI.latestScore.siScore,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.SIBASIC_SCORE) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.SIBasic.difference.siScore :
							a.SIBasic.latestScore.siScore,
						isDateRangeSelected ?
							b.SIBasic.difference.siScore :
							b.SIBasic.latestScore.siScore,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.OVERALL) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						isDateRangeSelected ?
							a.overall.difference.total :
							a.total.overall,
						isDateRangeSelected ?
							b.overall.difference.total :
							b.total.overall,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.CONTESTS_SUB1) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						a.internalContestScores[0]?.score || -1,
						b.internalContestScores[0]?.score || -1,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.CONTESTS_SUB2) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						a.internalContestScores[1]?.score || -1,
						b.internalContestScores[1]?.score || -1,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.CONTESTS_SUB3) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						a.internalContestScores[2]?.score || -1,
						b.internalContestScores[2]?.score || -1,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.CONTESTS_SUB4) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						a.internalContestScores[3]?.score || -1,
						b.internalContestScores[3]?.score || -1,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.CONTESTS_SUB5) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						a.internalContestScores[4]?.score || -1,
						b.internalContestScores[4]?.score || -1,
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.EXPERIENCE) {
				data.sort((a: any, b: any) =>
					this.compareStrings(
						a[sort.active].length > 0 ? (a[sort.active][0]['organization'] || '') : '',
						b[sort.active].length > 0 ? (b[sort.active][0]['organization'] || '') : '', isAsc
					));
			} else if (
				sort.active === this.matSortHeaderNames.USERNAME ||
				sort.active === this.matSortHeaderNames.NAME ||
				sort.active === this.matSortHeaderNames.MOBILE ||
				sort.active === this.matSortHeaderNames.EMAIL
			) {
				data.sort((a: any, b: any) =>
					this.compareStrings(a[sort.active] || '', b[sort.active] || '', isAsc)
				);
			} else if (sort.active === this.matSortHeaderNames.BRANCH) {
				data.sort((a: any, b: any) =>
					this.compareStrings(
						a.education ? (a.education[0]?.streamOrBoard || '') : '',
						b.education ? (b.education[0]?.streamOrBoard || '') : '',
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.INSTITUTION) {
				data.sort((a: any, b: any) =>
					this.compareStrings(
						a.education ? (a.education[0]?.institution || '') : '',
						b.education ? (b.education[0]?.institution || '') : '',
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.ROLLNUMBER) {
				data.sort((a: any, b: any) =>
					this.compareStrings(
						a.education ? (a.education[0]?.rollno || '') : '',
						b.education ? (b.education[0]?.rollno || '') : '',
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.ENDYEAR) {
				data.sort((a: any, b: any) =>
					this.compareStrings(
						a.education ? (a.education[0]?.endYear || '') : '',
						b.education ? (b.education[0]?.endYear || '') : '',
						isAsc
					)
				);
			} else if (sort.active === this.matSortHeaderNames.LASTLOGINTIME) {
				data.sort((a: any, b: any) =>
					this.compareNumbers(
						new Date(a[sort.active] || '3000-01-01').getTime(),
						new Date(b[sort.active] || '3000-01-01').getTime(),
						isAsc
					)
				);
			}
		} else if (sort.direction === '') {
			data.sort((a: any, b: any) =>
				this.compareNumbers(
					isDateRangeSelected ?
						a.overall.difference.total :
						a.total.overall,
					isDateRangeSelected ?
						b.overall.difference.total :
						b.total.overall,
					false
				)
			);
		}
		this.scoresSubject.next(data);
	}

	compareNumbers(a: number, b: number, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	compareStrings(a: string = '', b: string = '', isAsc: boolean) {
		return a.localeCompare(b) * (isAsc ? 1 : -1);
	}

	/**
	 * return nested property value
	 * @param o Object
	 * @param a Array
	 * @returns value of property "a.join('.')"
	 *
	 * @example
	 * ```
	 * let o = { x: { y: { z: '1234' } } }
	 * a = 'x.y.z'
	 * return '1234'
	 * ```
	 */
	getValueByArray = function (o, a) {
		for (let i = 0, n = a.length; i < n; ++i) {
			const k = a[i];
			if (k in o) {
				o = o[k];
			} else {
				return;
			}
		}
		return o;
	};

	onAddFilter(path, { minimum, maximum }) {
		this.filters[path] = { minimum, maximum };
		this.filterHelper();
	}

	onRemoveFilter(path) {
		delete this.filters[path];
		if (Object.keys(this.filters).length === 0) {
			const data = this.initialAllScores.slice();
			this.scoresSubject.next(data);
			this.filtersSubject.next({});
			if (this.lastSort != undefined) {
				this.onSortData(this.lastSort.sort, this.lastSort.isDateRangeSelected);
			}
			return;
		}
		this.filterHelper();
	}

	filterHelper() {
		let data = this.initialAllScores.slice();
		for (const prop in this.filters) {
			if (this.filters[prop]) {
				const arr = prop.split('.');
				data = data.filter((item) => {
					return (this.filters[prop].minimum ? this.getValueByArray(item, arr) >= this.filters[prop].minimum : true) &&
						(this.filters[prop].maximum ? this.getValueByArray(item, arr) <= this.filters[prop].maximum : true);
				});
			}
		}
		this.filtersSubject.next(this.filters);
		this.scoresSubject.next(data);
		if (this.lastSort != undefined) {
			this.onSortData(this.lastSort.sort, this.lastSort.isDateRangeSelected);
		}
	}

	clearFilters() {
		this.filtersSubject.next({});
	}
}
