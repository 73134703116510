import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthModule } from './registration/auth.module';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
// import { HomeModule } from './home/home.module';
// import { CoursesModule } from './courses/courses.module';
// import { FaqModule } from './faq/faq.module';
// import { AboutModule } from './about/about.module';
import {
	HeaderComponent,
	ApiService,
	JwtService,
	UserService,
	AuthGuard,
	VerifiedGuard,
	SharedModule,
	OtpService,
	HelpersService,
	AdminGuard,
	AdminService
} from './shared';
import { OtpModule } from './otp/otp.module';
import { LayoutModule } from '@angular/cdk/layout';
import { NgMaterialModule } from './shared/ng-material/ng-material.module';
import { UsersModule } from './users/users.module';
import { AppRoutingModule } from './app-routing.module';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TargetService } from './shared/services/target.service';
import { AttendanceService } from './shared/services/attendance.service';
import { XlsxService } from './shared/services/xlsx.service';
import { BannerComponent } from './banner/banner/banner.component';
const rootRouting = RouterModule.forRoot([], { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' });


@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		PageNotFoundComponent,
		BannerComponent
	],
	imports: [
		BrowserModule,
		rootRouting,
		AuthModule,
		// HomeModule,
		// CoursesModule,
		SharedModule,
		// AboutModule,
		OtpModule,
		// FaqModule,
		UsersModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			preventDuplicates: true,
			/** TODO: Change colour of progress bar to something more contrasty */
			progressBar: true,
			resetTimeoutOnDuplicate: true
		}),

		LayoutModule,
		NgMaterialModule,
		AppRoutingModule
	],
	providers: [
		ApiService,
		AuthGuard,
		VerifiedGuard,
		JwtService,
		UserService,
		AdminService,
		OtpService,
		HelpersService,
		AdminGuard,
		TargetService,
		XlsxService,
		AttendanceService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
