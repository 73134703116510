import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';

@Injectable({ providedIn: 'root' })
export class VideoService {
	public cloudFlareUrl = `${environment.api_url}/cloudflare`;
	public videoUrl = `${environment.api_url}/video`;
	constructor(
		private apiService: ApiService,
		private jwtService: JwtService
	) { }

	getBatchType(batchName: string) {
		const route = '/getBatchType';
		return this.apiService.post(`${this.videoUrl}${route}`, { batchName });
	}

	getBatchVideoList(batchName: string) {
		const route = '/getBatchVideoList';
		return this.apiService.post(`${this.videoUrl}${route}`, { batchName });
	}

	getVideoId(videoId: string, batchName: string, deviceInfo: any) {
		const route = '/requestAccess';
		return this.apiService.post(`${this.cloudFlareUrl}${route}`, { videoId, batchName, deviceInfo });
	}

	getAccessedVideosList(batchName: string) {
		const route = '/getAccessedVideosList';
		return this.apiService.post(`${this.videoUrl}${route}`, { batchName });
	}

	updateVideoTime(videoId: string, time: Number, batchName: string, token: string) {
		const route = '/updateVideoTime';
		return this.apiService.post(`${this.videoUrl}${route}`, { videoId, time, batchName, token });
	}

	addTopicTag(videoId: string, topicName: string, batchName: string) {
		const route = '/addTopicTag';
		return this.apiService.post(`${this.videoUrl}${route}`, { videoId, topicName, batchName });
	}

	removeTopicTag(videoId: string, topicName: string, batchName: string) {
		const route = '/removeTopicTag';
		return this.apiService.post(`${this.videoUrl}${route}`, { videoId, topicName, batchName });
	}
}
