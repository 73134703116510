import { Component, Input } from '@angular/core';

@Component({
	selector: 'testimonial-card',
	templateUrl: './testimonialcard.component.html',
	styleUrls: ['./cards.component.scss']
})
export class TestimonialcardComponent {
    @Input() testimonial: any;
}
