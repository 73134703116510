import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private userService: UserService
	) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		this.userService.isAuthenticated.subscribe((data) => {
			if (!data) {
				this.router.navigate(['/login'], {
					queryParams: { returnUrl: state.url },
					queryParamsHandling: 'merge'
				});
			}
		});
		return this.userService.isAuthenticated.pipe(take(1));
	}
}

@Injectable()
export class VerifiedGuard implements CanActivate {
	constructor(
		private router: Router,
		private userService: UserService
	) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		this.userService.isVerified.subscribe((data) => {
			if (!data) {
				this.userService.isAuthenticated.pipe(take(1)).subscribe((data) => {
					if (!data) {
						let redirectURL = '/login';
						/** redirect to /register if it's from /register/batch(payment page) */
						if (state.url.includes('/register/batch')) {
							redirectURL = '/register';
						}
						this.router.navigate([redirectURL], {
							queryParams: { returnUrl: state.url },
							queryParamsHandling: 'merge'
						});
					} else {
						this.router.navigate(['/verifyotp']);
					}
				});
			}
		});
		return this.userService.isVerified.pipe(take(1));
	}
}
