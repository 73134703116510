import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactDialogFormComponent, ContactFormComponent } from './contact-form.component';
import { NgMaterialModule } from '../ng-material/ng-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
	imports: [
		CommonModule,
		NgMaterialModule,
		FlexLayoutModule,
		ReactiveFormsModule,
		FormsModule
	],
	declarations: [
		ContactFormComponent,
		ContactDialogFormComponent
	],
	exports: [
		ContactFormComponent,
		ContactDialogFormComponent
	]
})
export class ContactFormModule { }
