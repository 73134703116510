<div>
	<p>Please fill out the form below for your query and we will get back to you as soon as possible!</p>
	<br />
	<div mat-dialog-content [formGroup]="contactForm">
		<div fxLayout="column">

			<!-- Full Name -->
			<mat-form-field fxFlex appearance="outline">
				<mat-label>Full Name</mat-label>
				<input required matInput formControlName="fullname" minlength="3" maxlength="100"
					placeholder="Enter your Full Name" />
				<mat-error>{{getErrorMessage('fullname', contactForm)}}</mat-error>
			</mat-form-field>

			<!-- Mobile Number -->
			<!-- <mat-form-field fxFlex appearance="outline">
                <mat-label>Mobile Number</mat-label>
                <input required matInput formControlName="mobile" minlength="8" maxlength="15" />
                <mat-error>{{getErrorMessage('mobile', contactForm)}}</mat-error>
            </mat-form-field> -->
			<div fxFlex="100%" fxLayout="row" fxLayoutGap="5px">
				<mat-form-field appearance="outline" fxFlex="0 0 100px">
					<mat-select #cc formControlName="cc"
						(selectionChange)="setCCValidator(contactForm.controls.mobile, cc)">
						<mat-option *ngFor="let cc of countriesList" [value]="cc">
							{{cc}} (+{{getCountryCallingCode(cc)}})
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" fxFlex>
					<mat-label>Mobile</mat-label>
					<input #mobile required matInput (keyup)="asYouType(mobile, cc, contactForm.controls['mobile'])"
						(keydown)="helpers.onlyNumbers($event);" formControlName="mobile" type="text" maxlength="15" />
					<mat-error>{{getErrorMessage('mobile', contactForm)}}</mat-error>
				</mat-form-field>
			</div>

			<!-- Email Id -->
			<mat-form-field fxFlex appearance="outline">
				<mat-label>Email Id</mat-label>
				<input required matInput formControlName="email" type="email" />
				<mat-error>{{getErrorMessage('email', contactForm)}}</mat-error>
			</mat-form-field>

			<!-- Message -->
			<mat-form-field fxFlex appearance="outline">
				<mat-label>Message</mat-label>
				<input #message required matInput formControlName="message" minlength="10" maxlength="250"
					placeholder="Enter your message here..." />
				<mat-hint align="end" *ngIf="!contactForm.controls['message'].errors?.maxlength">
					{{message.value?.length || 0}}/250
				</mat-hint>
				<mat-error>{{getErrorMessage('message', contactForm)}}</mat-error>
			</mat-form-field>

			<!-- How did you hear about us? -->
			<mat-form-field fxFlex appearance="outline">
				<mat-label>How did you hear about us?</mat-label>
				<mat-select required formControlName="surveyOptions">
					<mat-option *ngFor="let surveyOption of surveyOptionsList" [value]="surveyOption">
						{{surveyOption}}
					</mat-option>
				</mat-select>
				<mat-error>Required</mat-error>
			</mat-form-field>
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-button (click)="onDismiss()">Cancel</button>
		<button [disabled]="!contactForm.valid" mat-raised-button color="primary" (click)="sendMessage()" mat-button
			[mat-dialog-close]="true">Send Message</button>
	</div>
</div>