import { map, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User, CodingProfile } from '../models';
import { ContactFormDetails } from '../contact-form/contact-form.component';

@Injectable()
export class UserService {
	private currentUserSubject = new BehaviorSubject<User>(new User());
	public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

	private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
	public isAuthenticated = this.isAuthenticatedSubject.asObservable().pipe(distinctUntilChanged());
	private isVerifiedSubject = new ReplaySubject<boolean>(1);
	public isVerified = this.isVerifiedSubject.asObservable().pipe(distinctUntilChanged());

	private roleSubject = new ReplaySubject<number>(1);
	public roleOfTheUser = this.roleSubject.asObservable().pipe(distinctUntilChanged());

	private codingProfileSubject = new BehaviorSubject<CodingProfile>(new CodingProfile());
	public codingProfiles = this.codingProfileSubject.asObservable().pipe(distinctUntilChanged());

	constructor(
		private apiService: ApiService,
		private httpClient: HttpClient,
		private jwtService: JwtService
	) { }

	/**
	 * This runs on application startup
	 */
	populate() {
		/**
		 * If localstorage has JWT token, validate it with server and load user related info
		 */
		if (this.jwtService.getToken()) {
			const route = '/populateProfile';
			const req = {};
			this.apiService.post(`${environment.profile_url_v2}${route}`, req)
				.subscribe(
					(resp) => {
						if (!resp.status) {
							this.purgeAuth();
							return;
						}
						resp.data.token = this.jwtService.getToken();
						this.setAuth(resp.data);
					},
					(err) => this.purgeAuth()
				);
		} else {
			/**
			 * Remove any potential remnants of previous auth states
			 */
			this.purgeAuth();
		}
	}

	setAuth(user: User) {
		// Save JWT sent from server in localstorage
		this.jwtService.saveToken(user.token);
		this.jwtService.saveUsername(user.username.toLowerCase(), user.roleLevel, user.role);
		// Set current user data into observable
		this.currentUserSubject.next(user);
		// Set isAuthenticated to true
		this.isAuthenticatedSubject.next(true);
		this.currentUser.subscribe((data) => {
			this.isVerifiedSubject.next(data.isAccountVerified);
		});
		this.roleSubject.next(user.roleLevel);
	}

	purgeAuth() {
		// Remove JWT from localstorage and save username
		this.jwtService.destroyToken();
		this.jwtService.destroyUsername();
		this.jwtService.removeHash();
		this.jwtService.removeEmailOrMobile();
		// Set current user to an empty object
		this.currentUserSubject.next(new User());
		this.codingProfileSubject.next(new CodingProfile());
		// Set authenticated and verified status to false
		this.isVerifiedSubject.next(false);
		this.isAuthenticatedSubject.next(false);
		this.jwtService.userTemp = undefined;
		this.jwtService.type = undefined;
	}

	checkUser(data) {
		const url = '/checkUserExists';
		return this.apiService.post(`${environment.user_url}${url}`, data);
	}

	checkVerifiedUser(data) {
		const url = '/checkVerifiedUserExists';
		return this.apiService.post(`${environment.user_url}${url}`, data);
	}

	checkWhatsapp(data) {
		const url = '/checkWhatsappExists';
		const req = {};
		req['value'] = data;
		return this.apiService.post(`${environment.user_url}${url}`, req);
	}

	editPersonalDetails(data) {
		const route = '/editProfile';

		// Using shareReplay to handle multiple subscriptions concurrently
		const ret = this.apiService.post(`${environment.profile_url_v2}${route}`, data).pipe(shareReplay(1));
		ret.subscribe((res) => {
			this.jwtService.saveUsername(res.data.username.toLowerCase(), res.data.roleLevel, res.data.role);
			this.jwtService.saveToken(res.data.token);
			const user = res.data;
			this.currentUserSubject.next(user);
		});
		return ret;
	}

	checkCodingDetails(data) {
		const route = '/updateCodingUsername';
		data['username'] = this.jwtService.getUsername();
		return this.apiService.post(`${environment.profile_url_v2}${route}`, data);
	}

	updateData(data) {
		const route = '/enrollment/updateData';
		return this.apiService.post(`${environment.api_url}${route}`, data);
	}

	updateCodingProfiles(data) {
		this.codingProfileSubject.next(data);
	}

	getCodingProfiles() {
		return this.codingProfileSubject.getValue();
	}

	fetchCodingProfiles() {
		const route = '/getCodingDetails';
		const ret = this.apiService.post(`${environment.profile_url_v2}${route}`, {}).pipe(shareReplay(1));
		ret.subscribe(
			(resp) => {
				this.codingProfileSubject.next(resp.data);
			}
		);
		return ret;
	}

	fetchScore() {
		const route = '/fetchScore';
		return this.apiService.post(`${environment.user_url}${route}`, {});
	}

	getRequiredFieldsandEnroll(batchId, canEnroll: boolean = false) {
		const route = '/getRequiredFieldsAndEnroll';
		const data = {};
		data['username'] = this.jwtService.getUsername();
		data['batchId'] = batchId;
		data['canEnroll'] = canEnroll;
		return this.apiService.post(`${environment.user_url}${route}`, data);
	}

	storeVerifiedField() {
		const data = {};
		data['username'] = this.jwtService.getUsername();
		data['hashedValue'] = this.jwtService.getHash();
		const route = '/storeVerifiedCredential';
		return this.apiService.post(`${environment.user_url}${route}`, data);
	}

	changePassword(data) {
		const route = '/changePassword';
		data['username'] = this.jwtService.getUsername();
		return this.apiService.post(`${environment.profile_url_v2}${route}`, data);
	}

	getBatchCards(type: string[]) {
		const route = '/cards';
		const data = {
			'type': type
		};
		return this.apiService.post(`${environment.user_url}${route}`, data);
	}

	enrollForCourse(batchId) {
		const route = '/enroll';
		const data = {};
		data['username'] = this.jwtService.getUsername();
		data['batchId'] = batchId;
		return this.apiService.post(`${environment.user_url}${route}`, data);
	}

	registerWithBatch(batch) {
		const route = '/registerWithBatch';
		const data = {};
		data['username'] = this.jwtService.getUsername();
		data['batch'] = batch;
		return this.apiService.post(`${environment.user_url}${route}`, data);
	}

	attemptAuth(type: String, credentials): Observable<any> {
		const route = type === 'register' ? '/signup' : `/${type}`;
		return this.apiService.post(`${environment.user_url_v2}/authenticate${route}`, credentials).pipe(
			map(
				(resp: any) => {
					if (resp.status) {
						this.jwtService.saveUsername(credentials.username.toLowerCase(), credentials.roleLevel, credentials.role);

						if (resp.data.attempts !== -1) {
							this.setAuth(resp.data);
							this.populate();
						}

						/**
						 * Check if the account is verified
						 */
						if (!resp.data.isAccountVerified) {
							this.isVerifiedSubject.next(false);
							/**
							 * Store hashedValue in LocalStorage as we have sent an otp to either Mobile or Email
							 */
							this.jwtService.saveHash(resp.data.hashedValue);
						} else {
							this.isVerifiedSubject.next(true);
						}
					}

					/**
					 * resp.status is false case will be handled in the caller
					 */
					return resp;
				}
			));
	}

	updateCurrentUser(data: User) {
		this.currentUserSubject.next(data);
	}

	// Update the user on the server (email, pass, etc)
	update(user): Observable<User> {
		const route = '/user';
		return this.apiService
			.put(`${environment.user_url}${route}`, { user }).pipe(
				map((data) => {
					// Update the currentUser observable
					this.currentUserSubject.next(data.user);
					return data.user;
				}));
	}

	updateVerified() {
		this.isVerifiedSubject.next(true);
	}

	resumeUpload(file: FormData) {
		const route = '/uploadResume';
		const username = this.jwtService.getUsername().toString();
		/** TODO change `name` to `username` and update back-end accordingly */
		file.set('name', username);
		file.set('enctype', 'multipart/form-data');
		return this.apiService.postFile(`${environment.s3_url}${route}`, file);
	}

	// Uses apiService.postFile instead of apiService.post
	imageUpload(file: FormData) {
		const route = '/uploadImage';
		const username = this.jwtService.getUsername().toString();
		/** TODO change `name` to `username` and update back-end accordingly */
		file.set('name', username);
		file.set('enctype', 'multipart/form-data');
		return this.apiService.postFile(`${environment.s3_url}${route}`, file);
	}

	/**
	 * @description get the image url for the current user
	 */
	getImageUrl(username?: string): Observable<any> {
		const data = {};
		const route = '/getImage';
		const uname = this.jwtService.getUsername();
		data['username'] = (username ? username : uname);
		return this.apiService.post(`${environment.s3_url}${route}`, data);
	}

	getProfessionalDetails(): Observable<any> {
		const data = {};
		const route = '/getProfessionalDetails';
		const username = this.jwtService.getUsername().toString();
		data['username'] = username;
		return this.apiService.post(`${environment.profile_url_v2}${route}`, data);
	}

	/**
	 * Returns the details of the provided username
	 * @param username of the profile
	 */
	fetchProfile(username: string): Observable<any> {
		const route = `/user/${username}`;
		return this.apiService.post(`${environment.profile_url_v2}${route}`);
	}

	/**
	 * Fetches a list of companies and roles from the db
	 */
	fetchSuggestions(): Observable<any> {
		const route = '/suggestions';
		return this.apiService.get(`${environment.profile_url_v2}${route}`);
	}

	/**
	 * Updates the entire professional details of the user
	 * @param updateData Professional Details to be updated
	 */
	updateProfessionalDetails(updateData: any): Observable<any> {
		const route = '/updateProfessional';
		updateData['username'] = this.jwtService.getUsername();
		return this.apiService.post(`${environment.profile_url_v2}${route}`, updateData);
	}

	updatePrivacy(data): Observable<any> {
		const route = '/updatePrivacy';
		data['username'] = this.jwtService.getUsername();
		return this.apiService.post(`${environment.profile_url_v2}${route}`, data);
	}

	/**
	 * Fetches the scores of the requested paginated data.
	 * batchId is optional, by default fetches scores from all batches
	 */
	fetchLeaderboard(pageIndex: number, pageSize: number, batchName?: string): Observable<any> {
		let route = '/leaderboard/';
		const body = {};
		body['pageSize'] = pageSize;
		if (batchName) {
			route += `batch/${batchName}/${pageIndex}`;
			return this.apiService.post(`${environment.user_url}${route}`, body);
		} else {
			route += `${pageIndex}`;
			return this.apiService.post(`${environment.user_url}${route}`, body);
		}
	}

	/**
	 * Fetches the details of all the users of `batchName`
	 */
	fetchBatchReport(batchName: string, startDate?: Date, endDate?: Date): Observable<any> {
		let data = {};
		if (startDate && endDate) {
			data = {
				startDate: startDate,
				endDate: endDate
			};
		}
		const route = '/leaderboard/report/' + batchName;
		return this.apiService.post(`${environment.user_url}${route}`, data);
	}

	/**
	 * @param data
	 * Send Message to admin about contact information...
	 */
	sendContactForm(data: ContactFormDetails) {
		const route = '/contactForm';
		return this.apiService.post(`${environment.user_url}${route}`, data);
	}

	fetchNotificationContent() {
		const route = '/getNotification';
		return this.apiService.get(`${environment.user_url}${route}`);
	}

	getUnsubscriptionReasons() {
		const route = '/unsubscribe/reasons';
		return this.apiService.get(`${environment.api_url}${route}`);
	}

	unsubscribeFinally(data) {
		const route = '/unsubscribe/execute';
		return this.apiService.put(`${environment.api_url}${route}`, data);
	}

	updateBatchScores(batchId: Number) {
		const route = `/updateBatchScores/${batchId}`;
		return this.apiService.get(`${environment.user_url}${route}`);
	}

	getCertificate(batchName) {
		const route = '/certificate/generate';
		const body = {};
		body['batchName'] = batchName;
		return this.apiService.post(`${environment.api_url}${route}`, body);
	}

	enrollDetails(batchName: string) {
		const route = '/enrollment/enrollDetails';
		const params = new HttpParams()
			.set('batchName', batchName);
		return this.apiService.get(`${environment.api_url}${route}`, params);
	}

	getSurveyOptionsList() {
		const route = '/config/getSurveyOptionsList';
		return this.apiService.get(`${environment.api_url}${route}`);
	}

	/**
	* Updates the Internal Contest
	* @param data
	*/
	updateInternalContest(data) {
		const route = '/instructorOptions/updateInternalContest';
		return this.apiService.put(`${environment.api_url}${route}`, { data: [data] });
	}

	getBannerDetails() {
		const route = `${environment.user_url}/bannerDetails`;
		return this.apiService.get(route);
	}

	updateGithubUsername(username: string) {
		const route = `${environment.user_url}/githubUsername`;
		return this.apiService.post(route, { username });
	}

	updateLinkedInURL(url: string) {
		const route = `${environment.profile_url_v2}/updateLinkedinURL`;
		return this.apiService.post(route, { url });
	}

	saveNotifyUpcomingBatchData(data: any) {
		const route = `${environment.user_url}/notifyUpcomingBatch`;
		return this.apiService.post(route, data);
	}

	sendReferralQueryParams(refId: string, rc: string) {
		const route = '/referral';
		let params;
		if (refId) {
			params = new HttpParams()
				.set('refId', refId);
		} else {
			params = new HttpParams()
				.set('rc', rc);
		}
		return this.apiService.get(`${environment.api_url}${route}`, params);
	}

	upcomingSmartCoderBatch() {
		const route = `${environment.user_url}/upcomingSmartCoderBatch`;
		return this.apiService.get(route);
	}
}
