import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlatformImages } from '../enums';

@Component({
	selector: 'app-codingdetailsdialog',
	templateUrl: './codingdetailsdialog.component.html',
	styleUrls: ['./codingdetailsdialog.component.scss']
})
export class CodingDetailsDialog implements OnInit {
	PlatformImages = PlatformImages;

	constructor(
		public dialogRef: MatDialogRef<CodingDetailsDialog>,
		@Inject(MAT_DIALOG_DATA) public data: { heading: string, body: string }
	) { }

	onNoClick(): void {
		this.dialogRef.close();
	}

	openImage(platform: string): void {
		const url = PlatformImages[platform];
		window.open(url, 'Image');
	}

	ngOnInit(): void {
	}
}
