import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AdminGuard implements CanActivate {
	constructor(
        private router: Router,
        private userService: UserService,
        private toastr: ToastrService
	) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.userService.roleOfTheUser
				.pipe(take(1))
				.subscribe((level) => {
					if (level > 1) {
						resolve(true);
					} else {
						this.router.navigate(['/']);
						this.toastr.warning('Unauthorised access');
						resolve(false);
					}
				});
		});
	}
}
