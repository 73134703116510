export class ExperienceModel {
	_id: any;
	organization?: string;
	position?: string;
	package?: number;
	currency?: string;
	description?: string;
	startDate?: Date;
	endDate?: Date;
	creditSI?: boolean;

	constructor(data) {
		this._id = data._id;
		this.organization = data.organization;
		this.position = data.position;
		this.package = data.package;
		this.currency = data.currency;
		this.description = data.description;
		this.startDate = data.startDate;
		this.endDate = data.endDate;
		this.creditSI = data.creditSI;
	}
}
