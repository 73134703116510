// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
const baseUrl = 'http://127.0.0.1:3000';
const v1Url = baseUrl + '/api';
const v2Url = baseUrl + '/api/v2';

export const environment = {
	production: false,

	api_url: v1Url,
	user_url: v1Url + '/user-control',
	otp_url: v1Url + '/otp-control',
	s3_url: v1Url + '/s3-control',
	admin_url: v1Url + '/admin-control',
	certificate_url: v1Url + '/certificate/getCertificate',

	/** V2 URLs */
	user_url_v2: v2Url + '/user-control',
	profile_url_v2: v2Url + '/user-control/profile',


	recaptcha: {
		siteKeyV2: '6Lc0a-EeAAAAANeVIRr3bQl4Ua2f5-0F2ZGUS6Qp',
		siteKeyV3: '6LdpCt8fAAAAAMAyZ7j0AOabyfbAz3dm_CzOhopI'
	},

	razorpay: {
		key: 'rzp_test_Mducq1kgHGiQX0'
	}
};
