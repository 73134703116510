import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAttendance } from '../../report/report.component';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
@Injectable()
export class AttendanceService {
	public attendanceUrl = `${environment.api_url}/attendance`;
	constructor(
		private apiService: ApiService
	) { }

	getAllAttendances() {
		const route = `${this.attendanceUrl}/getAllAttendances`;
		return this.apiService.get(route);
	}

	getAttendancesOfBatchId(batchId: number) {
		const route = `${this.attendanceUrl}/getAttendancesOfBatchId/${batchId}`;
		return this.apiService.get(route);
	}

	getCurrentlyEnabledAttendanceOfBatch(batchId: number) {
		const route = `${this.attendanceUrl}/getCurrentlyEnabledAttendanceOfBatch/${batchId}`;
		return this.apiService.get(route);
	}

	showAttendanceNotification(batchId: number) {
		const route = `${this.attendanceUrl}/showAttendanceNotificationForUser/${batchId}`;
		return this.apiService.get(route);
	}

	createAttendance(data: IAttendance) {
		const route = `${this.attendanceUrl}/createAttendance`;
		return this.apiService.post(route, data);
	}

	updateAttendance(data: IAttendance) {
		const route = `${this.attendanceUrl}/updateAttendance`;
		return this.apiService.put(route, data);
	}

	markAttendance(batchId: number) {
		const route = `${this.attendanceUrl}/markAttendance/${batchId}`;
		return this.apiService.put(route, {});
	}

	viewAttendance(batchId: number) {
		const route = `${this.attendanceUrl}/viewAttendance/${batchId}`;
		return this.apiService.get(route);
	}

	sendAttendanceReport(attendanceId: string) {
		const route = `${this.attendanceUrl}/sendAttendanceReport/${attendanceId}`;
		return this.apiService.get(route);
	}

	sendOverallAttendanceReport(batchId: number) {
		const route = `${this.attendanceUrl}/sendOverallAttendanceReport/${batchId}`;
		return this.apiService.get(route);
	}

	viewActivityDetails(batchId: number) {
		const route = `${this.attendanceUrl}/viewActivityDetails/${batchId}`;
		return this.apiService.get(route);
	}

	getRegisteredStudentsList(batchId: string) {
		const route = `${this.attendanceUrl}/getRegisteredStudentsList`;
		const params = new HttpParams()
			.set('batchId', batchId);
		return this.apiService.get(route, params);
	}

	updateStudentsAttendanceList(data: any) {
		const route = `${this.attendanceUrl}/updateAttendanceList`;
		return this.apiService.put(route, data);
	}

	downloadOverallAttendanceReport(batchId: string) {
		const route = `${this.attendanceUrl}/downloadOverallAttendanceReport`;
		const params = new HttpParams()
			.set('batchId', batchId);
		return this.apiService.get(route, params);
	}

	downloadAttendanceReport(attendanceId: string, batchId: string) {
		const route = `${this.attendanceUrl}/downloadAttendanceReport`;
		const params = new HttpParams()
			.set('attendanceId', attendanceId)
			.set('batchId', batchId);
		return this.apiService.get(route, params);
	}
}
