<div class="wf-section">
	<div class="join-discord-div">
		<h2 class="heading-19">Join us on</h2>
		<a href="https://discord.gg/e7c2ZtDYsX" target="_blank" class="discord-button w-inline-block">
			<img src="/assets/images/discord-blue-icon.svg" class="discord-img" loading="lazy" alt="" />
		</a>
	</div>
</div>
<div class="body footer">
	<div class="container body-container footer-container w-container">
		<div class="footer-main-div">
			<div class="footer-section wf-section">
				<img class="footer-logo" src="/assets/images/SI.LOGO-02_1.svg" loading="lazy" width="116" sizes="116px"
					alt="" />
				<a href="mailto:contact@smartinterviews.in" class="link-block-2 w-inline-block">
					<p class="footer-email">contact@smartinterviews.in</p>
				</a>
				<a href="tel:+917272937937" class="link-block-2 w-inline-block">
					<p class="footer-email">+91 7272 937 937</p>
				</a>
				<div class="w-layout-grid grid-6">
					<a href="https://www.facebook.com/SmartInterviews" target="_blank" class="w-inline-block">
						<div id="w-node-ec360494-ee95-f5a1-a925-0ddb588e9972-44e078c3" class="footer-social-image-div">
							<img src="/assets/images/Facebook_icon.png" loading="lazy" width="30" height="30" alt=""
								class="footer-social-image" />
						</div>
					</a>
					<a href="https://discord.com/invite/e7c2ZtDYsX" target="_blank" class="w-inline-block">
						<div id="w-node-ec360494-ee95-f5a1-a925-0ddb588e9974-44e078c3" class="footer-social-image-div">
							<img src="/assets/images/Discord_icon.png" loading="lazy" width="30" height="30" alt=""
								class="footer-social-image" />
						</div>
					</a>
					<a href="https://www.linkedin.com/company/smart-interviews/" target="_blank" class="w-inline-block">
						<div id="w-node-ec360494-ee95-f5a1-a925-0ddb588e9976-44e078c3" class="footer-social-image-div">
							<img src="/assets/images/Linkedin_icon.png" loading="lazy" width="30" height="30" alt=""
								class="footer-social-image" />
						</div>
					</a>
					<a href="https://instagram.com/smartinterviews" target="_blank" class="w-inline-block">
						<div id="w-node-ec360494-ee95-f5a1-a925-0ddb588e9978-44e078c3" class="footer-social-image-div">
							<img src="/assets/images/Instagram_icon.png" loading="lazy" width="30" height="30" alt=""
								class="footer-social-image" />
						</div>
					</a>
					<a href="https://www.youtube.com/channel/UC3JtcFSnkIgi_l62SwnAcpA" target="_blank"
						class="w-inline-block">
						<div id="w-node-ec360494-ee95-f5a1-a925-0ddb588e997a-44e078c3" class="footer-social-image-div">
							<img src="/assets/images/Youtube_icon.png" loading="lazy" width="30" height="30" alt=""
								class="footer-social-image" />
						</div>
					</a>
				</div>
			</div>
			<div id="w-node-ec360494-ee95-f5a1-a925-0ddb588e997c-44e078c3" class="footer-section wf-section">
				<h3 class="footer-heading">Links</h3>
				<a href="/smart-coder" class="link-block-2 w-inline-block">
					<p class="footer-links-2">Smart Coder</p>
				</a>
				<a routerLink="/samplecodes" class="link-block-2 w-inline-block">
					<p class="footer-link">Sample Codes</p>
				</a>
				<a href="https://www.linkedin.com/company/smart-interviews/jobs/" target="_blank"
					class="link-block-2 w-inline-block">
					<p class="footer-link">Careers</p>
				</a>
				<a routerLink="/leaderboard" class="link-block-2 w-inline-block">
					<p class="footer-link">Leaderboard</p>
				</a>
			</div>
			<div id="w-node-ec360494-ee95-f5a1-a925-0ddb588e9987-44e078c3" class="footer-section wf-section">
				<h3 class="footer-heading">Support</h3>
				<a href="/faq" class="link-block-2 w-inline-block">
					<p class="footer-links-2">Frequently Asked Questions</p>
				</a>
				<a href="/contact-us" class="link-block-2 w-inline-block">
					<p class="footer-link">Contact us</p>
				</a>
				<a href="/terms-and-conditions" class="link-block-2 w-inline-block">
					<p class="footer-link">Terms and Conditions</p>
				</a>
				<a href="/privacy-policy" class="link-block-2 w-inline-block">
					<p class="footer-link">Privacy Policy</p>
				</a>
			</div>
		</div>
		<p class="paragraph-5">Copyright © 2022 | Smart Interviews | All Rights Reserved</p>
	</div>
</div>