<div class="auth-page">
	<div class="abstract abstract-desktop">
		<img class="abstract-img" src="../../assets/images/Logging_in.gif" />
	</div>
	<div class="abstract abstract-mobile">
		<img class="abstract-img" src="../../assets/images/bean_bag_girl.svg" />
	</div>

	<div class="login-container-container">
		<div fxLayout="column" class="login-container">

			<mat-card fxFlex="0 0 auto" class="login-card mat-elevation-z0">
				<form [formGroup]="authForm" novalidate fxLayout="column" fxLayoutGap="15px">
					<mat-form-field appearance="outline" fxFlex>
						<mat-label>Username</mat-label>
						<input matInput (input)="usernameExists = false" maxlength="16"
							(blur)="authType!='login' && checkUserExisting($event)" formControlName="username"
							type="text" name="username" id="autofocus" />
						<mat-hint *ngIf="authType!='login'">{{messageAuth.signUpForm.tooltip.username}}</mat-hint>
						<mat-error>{{getUsernameErrorMessage()}}</mat-error>

					</mat-form-field>

					<ng-container *ngIf="authType=='register'">
						<div fxFlex="100%" fxLayout="row" fxLayoutGap="5px">
							<mat-form-field appearance="outline" fxFlex="0 0 120px">
								<mat-select #cc formControlName="cc"
									(selectionChange)="setCCValidator(authForm.controls.mobile, cc)">
									<mat-option *ngFor="let cc of countriesList" [value]="cc">
										{{cc}} (+{{getCountryCallingCode(cc)}})
									</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field appearance="outline" *ngIf="authType=='register'" fxFlex>
								<mat-label>Mobile</mat-label>
								<input #mobile matInput (blur)="checkEmailMobile($event)" name="email_mobile"
									formControlName="mobile" type="text"
									(keyup)="asYouType(mobile, cc, authForm.controls.mobile)"
									(keydown)="helpers.onlyNumbers($event)" maxlength="15" />
								<mat-error>{{getMobileErrorMessage()}}</mat-error>
							</mat-form-field>
						</div>
					</ng-container>

					<mat-form-field appearance="outline" fxFlex>
						<mat-label>Password</mat-label>
						<input matInput maxlength="15" (input)="onPassword($event)" formControlName="password"
							id="password" type="password" />
						<mat-hint *ngIf="authType!='login'">
							<mat-progress-bar color="accent" *ngIf="!authForm.controls['password'].errors"
								mode="determinate" [value]="progressValue">
							</mat-progress-bar>
							{{passwordStrength}}
						</mat-hint>
						<mat-error>{{getPasswordErrorMessage()}}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" *ngIf="authType=='register'" fxFlex>
						<mat-label>Confirm password</mat-label>
						<input matInput maxlength="15" id="confirmpassword" formControlName="confirmpassword"
							type="password" />
						<mat-error>{{getConfirmPasswordErrorMessage()}}
						</mat-error>
					</mat-form-field>

					<mat-form-field fxFlex appearance="outline" *ngIf="authType=='register'">
						<mat-label>How did you hear about us?</mat-label>
						<mat-select formControlName="surveyOptions">
							<mat-option *ngFor="let surveyOption of surveyOptionsList" [value]="surveyOption">
								{{surveyOption}}
							</mat-option>
						</mat-select>
						<mat-error>Required</mat-error>
					</mat-form-field>

					<div class="privacy-policy" *ngIf="authType=='register'">
						<mat-checkbox (change)="isAgreedToPrivacyPolicy($event)">
							I agree to the <a href="/terms-and-conditions">Terms and Conditions</a> and
							<a href="/privacy-policy">Privacy Policy</a>
						</mat-checkbox>
					</div>

					<re-captcha #captchaRef="reCaptcha" (resolved)="recaptchaResolved($event)" size="invisible"
						[siteKey]="SITE_KEY">
					</re-captcha>
					<ng-container *ngIf="authType!='login'">
						<div matTooltipPosition="above"
							[matTooltip]="(authType=='register' && !isPrivacyPolicyAccepted) ? 'Please accept the Terms and Conditions' : ''">
							<button [disabled]="authType=='register' && !isPrivacyPolicyAccepted" mat-flat-button
								color="primary" fxFlex class="signup-button" (click)="handleSignUp()">
								<ng-container *ngIf="!showSpinner" style="margin:auto">
									{{title}}
								</ng-container>
								<ng-container *ngIf="showSpinner">
									<mat-spinner style="margin:auto;margin-top: 7px;margin-bottom: 7px;"
										[diameter]="22">
									</mat-spinner>

								</ng-container>
							</button>
						</div>
						<p class="redirect-para">Already have an account? <a [routerLink]="['/login']"
								queryParamsHandling="preserve">Login</a></p>
					</ng-container>
					<button *ngIf="authType==='login'" mat-flat-button color="primary" fxFlex="0 0 50px"
						(click)="this.submitForm(this.authForm.invalid)">
						{{title}}
					</button>

					<div *ngIf="authType==='login'" class="redirect-para">Don't have an account? <a
							[routerLink]="['/register']" queryParamsHandling="preserve">Sign up</a></div>

					<p style="opacity:0.4">
						This site is protected by reCAPTCHA and the Google
						<a href="https://policies.google.com/privacy">Privacy Policy</a> and
						<a href="https://policies.google.com/terms">Terms of Service</a> apply
					</p>

					<div *ngIf="authType==='login'" class="forgot-pass">
						<a [routerLink]="['/forgot-password']">{{messageAuth.login.forgotPassword}}</a>
					</div>
				</form>
			</mat-card>
		</div>
	</div>
</div>

<app-contact-form></app-contact-form>