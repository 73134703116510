<div class="page-wrapper">
	<div class="mat-body">
		<div class="body-wrapper" fxLayout="column">
			<div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
				<ng-container *ngIf="user$ | async as user">
					<div fxFlex="400px 1 0">
						<div fxLayout="column">
							<div fxFlex class="mat-elevation-z1 profile-picture">
								<img *ngIf="!user.data.personalDetails.dpImageFile" [src]="defaultPath" alt="" />
								<img *ngIf="user.data.personalDetails.dpImageFile"
									[src]="user.data.personalDetails.dpImageFile" alt="" />
							</div>
							<div *ngIf="user.data.isOwner" fxFlex>
								<mat-slide-toggle [labelPosition]="'before'" [color]="primary"
									[checked]="user.data.publicView" (change)="updatePrivacy($event)">
									Public profile
								</mat-slide-toggle>
							</div>
						</div>
					</div>
				</ng-container>
				<div fxFlex fxLayout="column" fxLayoutGap="30px">
					<ng-container *ngIf="user$ | async as user">
						<mat-card fxFlex class="mat-elevation-z1 card-1">
							<h1 [hidden]="user.data.personalDetails.name">{{user.data.usernameCaseSensitive}}</h1>
							<h1 [hidden]="!user.data.personalDetails.name">{{user.data.personalDetails.name}}</h1>
							<h3 [hidden]="!user.data.personalDetails.name" class="username-display">
								({{user.data.usernameCaseSensitive}})</h3>
							<h4>
								{{user.data.personalDetails.address?.city}}<span
									*ngIf="user.data.personalDetails.address?.city">,</span>
								{{user.data.personalDetails.address?.country}}
							</h4>
							<h4 *ngIf="user.data.overall != null">
								Overall score: {{user.data.overall.latestScore.score}}
							</h4>
							<h4 *ngIf="latestGlobalRank">
								Global rank: {{latestGlobalRank}}
								<!-- 
                                    TODO: This should be placed back once we stop updating ranks on daily basis
                                    As of now, cron-job runs daily and it doesn't use scoreFetchingFrequency of batch model
                                 -->
								<!-- <span class="rank-date">(as on {{user.data.rank.latestRank.date | date}})</span> -->
							</h4>
						</mat-card>
						<mat-card *ngIf="user.data.personalDetails.aboutMe" fxFlex class="mat-elevation-z1 card-1">
							<h3 class="mat-headline">About</h3>
							<p>{{user.data.personalDetails.aboutMe}}</p>
						</mat-card>
					</ng-container>

					<mat-card [ngStyle]="{'display': chart ? 'flex' : 'none'}" fxLayout="row wrap"
						fxLayoutAlign="space-evenly center" class="mat-elevation-z1 card-1">
						<canvas fxFlex id="rank-chart" class="chart">{{ chart }}</canvas>
						<canvas fxFlex fxFlex.gt-md="500px" id="score-chart" class="chart">{{ scoreChart }}</canvas>
					</mat-card>


					<ng-container *ngIf="user$ | async as user">
						<mat-card *ngIf="user.data.professionalDetails.experience?.length > 0" fxFlex
							class="mat-elevation-z1 card-1">
							<h3 class="mat-headline">Work experience</h3>
							<div *ngFor="let experience of user.data.professionalDetails.experience">
								<h4>{{experience.organization}}</h4>
								<p>{{experience.position}}</p>
								<p>{{experience.description}}</p>
								<br />
							</div>
						</mat-card>
						<mat-card *ngIf="user.data.professionalDetails.education?.length > 0" fxFlex
							class="mat-elevation-z1 card-1">
							<h3 class="mat-headline">Education</h3>
							<div *ngFor="let edu of user.data.professionalDetails.education">
								<h4>{{edu.institution}}</h4>
								<p>{{edu.degreeOrGrade}}</p>
								<p>{{edu.streamOrBoard}}</p>
								<p>{{edu.startYear | date: 'yyyy' }}</p>
								<p>{{edu.endYear | date: 'yyyy' }}</p>
								<br />
							</div>
						</mat-card>
					</ng-container>

				</div>
			</div>
		</div>
	</div>
</div>